/* eslint-disable */

import React, { useState } from "react";
import { Box, Container, Tab, Tabs } from "@mui/material";
import useAuth from "../../providers/AuthProvider/useAuth";
import useUserAccountQuery from "../../modules/account/queries/useUserAccountQuery";
import getId from "../../helpers/fp/models/getId";
import PageLoader from "../../components/shared/PageLoader";
import SomethingWentWrongAlert from "../../components/shared/Alerts/SomethingWentWrongAlert";
import NotFound from "../../components/shared/NotFound/NotFound";
import SessionsTab from "./SessionsTab/SessionsTab";
import ActivitiesTab from "./ActivitiesTab/ActivitiesTab";
import ProfileTab from "./ProfileTab/ProfileTab";
import UserPreview from "./UserPreview";

const TABS = {
  PROFILE: 0,
  ACTIVITY: 1,
  SESSIONS: 2,
}

const AccountPage = () => {
  const { authenticatedUser } = useAuth();

  const [selectedTab, setSelectedTab] = useState(TABS.PROFILE)

  const userAccountQuery = useUserAccountQuery({
    userId: getId(authenticatedUser),
  });

  if (!userAccountQuery.isFetched || userAccountQuery.isPending) {
    return (
      <PageLoader/>
    )
  }

  if (userAccountQuery.isError) {
    return (
      <SomethingWentWrongAlert onRefresh={userAccountQuery.refetch}/>
    )
  }

  if (!userAccountQuery.user) {
    return (
      <NotFound text="User account is not found!"/>
    )
  }

  const tabContent = {
    [TABS.PROFILE]: <ProfileTab user={userAccountQuery.user}/>,
    [TABS.ACTIVITY]: <ActivitiesTab user={userAccountQuery.user}/>,
    [TABS.SESSIONS]: <SessionsTab user={userAccountQuery.user}/>,
  }[selectedTab]

  return (
    <Container maxWidth="lg">
      <UserPreview user={userAccountQuery.user}/>
      <Box sx={{ borderBottom: 1, borderColor: 'primary.main' }}>
        <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
          <Tab label="Profile" value={TABS.PROFILE}/>
          <Tab label="Activity" value={TABS.ACTIVITY} disabled/>
          <Tab label="Sessions" value={TABS.SESSIONS}/>
        </Tabs>
      </Box>
      <Box style={{ marginTop: "1rem" }}>
        {tabContent}
      </Box>
    </Container>
  )
}

export default AccountPage
