import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import teamService from "../../../api/team";

export const teamsQueryKey = "teams";

export const teamsQueryOptions = (userId) => queryOptions({
  queryKey: [teamsQueryKey, {
    userId
  }],
  queryFn: async () => {
    return teamService.getTeams();
  },
});

const useTeamsQuery = ({
                         enabled = true,
                         userId
                       }) => {
  const { data,
    isFetching,
    isPending,
    isFetched,
    error,
    isError,
    refetch
  } = useQuery({
    ...teamsQueryOptions(userId),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: [],
    placeholderData: keepPreviousData
  })

  return {
    teams: data.data ?? [],
    isEmpty: data && !data?.length,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending,
    isFetched
  }
}

export default useTeamsQuery;
