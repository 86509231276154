// AXIOS
import { getTrap } from "../axiosInstance";


/** @param {string} id */
const getLocationsByProviderId = (id) => getTrap(`/providers/${id}/locations`);

const regionService = Object.freeze({
  getLocationsByProviderId,
});

export default regionService;
