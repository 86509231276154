// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import regionService from '../../api/location';

const sizesAsyncActions = Object.freeze({
  getLocationsByProviderId: createAsyncThunk('get/regions', regionService.getLocationsByProviderId),
});

export default sizesAsyncActions;
