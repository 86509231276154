import isPast from "../../../helpers/fp/date/isPast";

const isTeamDeactivated = (team) => {
  if (!team) {
    throw new Error("isTeamDeactivated: team is not set!")
  }

  return isPast(team.deactivatedAt)
}

export default isTeamDeactivated;
