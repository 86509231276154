/* eslint-disable */

// CORE
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

// REPORTS
import reportWebVitals from './reportWebVitals';
// COMPONENTS
import App from './App';

import store from './store';

import * as Sentry from "@sentry/react";
import ENV from './constants/ENV';
import AuthProvider from "./providers/AuthProvider/AuthProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider } from "@tanstack/react-query";
import keycloakService from "./services/keycloak/index";
import MetadataProvider from "./providers/MetadataProvider/MetadataProvider";
import ServerModeProvider from "./providers/ServerModeProvider";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import queryClient from "./libs/queryClient";

Sentry.init({
  dsn: ENV.REACT_APP_SENTRY_DSN,
  environment: ENV.REACT_APP_SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const ReactQueryDevtoolsProduction = React.lazy(() => import('@tanstack/react-query-devtools/build/modern/production.js')
  .then((module) => ({ default: module.ReactQueryDevtools })),
);

const showDevtools = Boolean(localStorage.getItem("tanstack-devtools"));

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <CssBaseline/>
        <ServerModeProvider>
          <AuthProvider client={keycloakService}>
            <MetadataProvider queryClient={queryClient}>
              <App/>
            </MetadataProvider>
          </AuthProvider>
        </ServerModeProvider>
      </BrowserRouter>
    </Provider>
    <ReactQueryDevtools client={queryClient} />
    {showDevtools && (
      <React.Suspense fallback={null}>
        <ReactQueryDevtoolsProduction client={queryClient} />
      </React.Suspense>
    )}
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
