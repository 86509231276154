import { Refresh } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const RefreshIconButton = ({
                         tooltip = "Refresh",
                         size = "small",
                         onClick,
                         disabled,
                       }) => {
  return (
    <Tooltip title={tooltip} arrow>
      <IconButton size={size}
                  onClick={onClick}
                  disabled={disabled}
                  aria-label={tooltip}
      >
        <Refresh fontSize={size}/>
      </IconButton>
    </Tooltip>
  );
};

export default RefreshIconButton;
