import dayjs from "../../../libs/dayjs";

import {
  RESOURCE_STATUS_INDICATOR_STATUSES
} from "../../../components/resources/shared/ResourceStatusIndicator/ResourceStatusIndicator";

export const IMAGES = {
  OPENDESK: "OpenDesk",
  NEXTCLOUD: "NextCloud",
};

export const IMAGE_TO_ADMIN_USERNAME = {
  [IMAGES.NEXTCLOUD]: "admin",
  [IMAGES.OPENDESK]: "Administrator",
};

export const appStatuses = Object.freeze({
  creating: 0,
  attesting: 1,
  completed: 2,
  activated: 3,
  deleting: 4,
  completedDelete: 5,
  deleted: 6,
  error: 7,
  installation: 8,
});

export const appStatusToResourceIndicatorStatus = {
  [appStatuses.creating]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [appStatuses.attesting]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [appStatuses.completed]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [appStatuses.activated]: RESOURCE_STATUS_INDICATOR_STATUSES.ACTIVE,
  [appStatuses.deleting]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [appStatuses.completedDelete]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [appStatuses.deleted]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [appStatuses.error]: RESOURCE_STATUS_INDICATOR_STATUSES.ERROR,
  [appStatuses.installation]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
}

export const appStatusesNames = Object.freeze({
  [appStatuses.creating]: "Creating",
  [appStatuses.attesting]: "Attesting",
  [appStatuses.completed]: "Activation",
  [appStatuses.activated]: "Working",
  [appStatuses.deleting]: "Deleting",
  [appStatuses.completedDelete]: "Deleting",
  [appStatuses.deleted]: "Deleted",
  [appStatuses.error]: "Failed",
  [appStatuses.installation]: "Installation",
});

export const loadAppStatuses = [
  appStatuses.creating,
  appStatuses.attesting,
  appStatuses.installation,
  appStatuses.completed,
];

export const deleteAppStatuses = [
  appStatuses.deleting,
  appStatuses.deleted,
  appStatuses.completedDelete,
];

export const initialSetupAppStatuses = [
  appStatuses.creating,
  appStatuses.attesting,
  appStatuses.installation,
  appStatuses.completed
]

export const cancellableAppStatuses = [
  appStatuses.creating,
  appStatuses.attesting,
  appStatuses.installation,
];

export const isActivated = (status) => status === appStatuses.activated;

export const isAppDeleted = (app) => !!app.deletedAt || [appStatuses.deleted, appStatuses.completedDelete].includes(app.status);

// Date when credentials section was added. We don't need to show it because some apps (nextcloud) were created without admin password
export const isCredentialsSectionAvailable = (createdAt) => dayjs(createdAt).isAfter(dayjs("2024-10-21"));
