// CORE
import { createSlice } from "@reduxjs/toolkit";
// ACTIONS
import asyncActions from "./asyncActions";

const serverModeSlice = createSlice({
  name: "serverMode",
  initialState: {
    items: [],
    isLoading: false,
    isError: false,
    isFetched: false,
  },
  reducers: {
    updateSeverMode(state, { payload }) {
      state.isError = false;
      state.items = state.items.map((mode) =>
        mode.type === payload.type ? payload : mode
      );

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getServerModes.fulfilled, (state, { payload }) => {
        state.items = payload.data;
        state.isLoading = false;
        state.isFetched = true;
      })
      .addCase(asyncActions.getServerModes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(asyncActions.getServerModes.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.isFetched = true;
      });
  },
});

export default {
  ...serverModeSlice,
  asyncActions,
};
