/* eslint-disable */
// CORE
import { Link, useNavigate } from "react-router-dom";

import React, { useCallback, useState } from "react";

import "./AccountInfo.scss";

// MUI
import {
  Badge,
  Box,
  List,
  ListItem,
  Popover,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import classNames from "classnames";
import {
  accountRoute,
  billingRoute,
  createTeamRoute,
  settingsRouteBuilder,
} from "../../../constants/routes/routes";
import Null from "../../../helpers/fp/function/Null";
import TeamAvatar from "../../common/TeamAvatar";
import UserAvatar from "../UserAvatar";
import getCost from "../../../helpers/getCost";
import useCurrentTeam from "../../../providers/MetadataProvider/hooks/useCurrentTeam";
import useActualCostsByTeam from "../../../hooks/actualCost/useActualCostsByTeam";
import useFetchActualCostsByTeam from "../../../hooks/actualCost/useFetchActualCostsByTeam";
import useAuth from "../../../providers/AuthProvider/useAuth";
import useTeamsQuery from "../../../modules/team/queries/useTeamsQuery";
import useIsCurrentTeamOwner from "../../../providers/MetadataProvider/hooks/useIsCurrentTeamOwner";
import isPast from "../../../helpers/fp/date/isPast";
import getNameAbbr from "../../../helpers/string/getNameAbbr";
import getFullName from "../../../helpers/string/getFullName";

const ceilCost = (cost) => Math.ceil(cost * 100) / 100;

const AccountInfo = ({ setNextTeam }) => {
  const { 0: anchorEl, 1: setAnchorEl } = useState(Null);
  const { team } = useCurrentTeam();
  const { authenticatedUser, logout } = useAuth();

  const { teams } = useTeamsQuery({
    userId: authenticatedUser.id
  });

  const isTeamOwner = useIsCurrentTeamOwner()
  const navigate = useNavigate();
  const actualCosts = useActualCostsByTeam(team);
  const fetchActualCostsByTeam = useFetchActualCostsByTeam(team);
  const [isLoadingActualCosts, setIsLoadingActualCosts] = useState(!actualCosts.length);
  if (isLoadingActualCosts && team) fetchActualCostsByTeam(team).finally(() => setIsLoadingActualCosts(false));

  const [showTeams, setShowTeams] = useState(false);

  const handleChangeShowTeams = useCallback(() => {
    setShowTeams((show) => !show);
  }, [setShowTeams]);

  const handleClose = useCallback(() => {
    setAnchorEl(Null);
    setShowTeams(false);
  }, []);

  const handleSwitchTeam = useCallback(
    async (e) => {
      const teamId = e.currentTarget.getAttribute("data-team-id");
      if (teamId === team?.id) return null;

      const nextTeam = teams.find((team) => team.id === teamId);
      if (!nextTeam) return null;
      setNextTeam(nextTeam);
      handleClose();
    },
    [setNextTeam, teams, team]
  );

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isDisabledCreate = isPast(team?.suspendedAt) || !authenticatedUser?.emailVerified;

  return (
    <Box
      className="dashboard-header__account-wrapper"
      sx={{
        flexGrow: 1,
      }}
    >
      <Box minWidth="140px">
        <Typography
          className="dashboard-header__account-name"
          variant="subtitle1"
        >
          {team?.name}
        </Typography>
        <Typography
          className="dashboard-header__balance"
          color="black"
          variant="body1"
        >
          <Link to={billingRoute + "#summary"} className="inherit">
            Estimated costs € {
              isLoadingActualCosts
                ? "..."
                : getCost(actualCosts.reduce((total, { cost }) => total + ceilCost(cost), 0))
            }
          </Link>
        </Typography>
      </Box>
      <Box onClick={handleClick} className="dashboard-header__avatar-wrap">
        <Badge
          sx={{ marginLeft: "16px" }}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <UserAvatar style={{ width: "22px", height: "22px" }} user={authenticatedUser} />
          }
        >
          <TeamAvatar teamName={
            getNameAbbr(
              authenticatedUser.firstName,
              authenticatedUser.lastName
            )
          } />
        </Badge>
        <ExpandMoreIcon className={anchorEl ? "up" : ""} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="profile-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          className={classNames("profile-container", {
            "profile-container__teams": showTeams,
          })}
        >
          <Box className="profile">
            <UserAvatar user={authenticatedUser} />
            <Box className="profile__info-wrapper">
              <Typography variant="body2">
                {getFullName(authenticatedUser.firstName, authenticatedUser.lastName)}
              </Typography>
              <Typography variant="body2">{isTeamOwner ? "Owner" : "Member"}</Typography>
            </Box>
          </Box>
          {!showTeams && (
            <List className="menu-customer">
              <ListItem
                sx={{
                  pointerEvents: authenticatedUser.emailVerified ? 'auto' : 'none',
                  opacity: authenticatedUser.emailVerified ? 1 : 0.5,
                }}
                component={Link}
                to={accountRoute}>
                My Account
              </ListItem>

              <ListItem sx={{
                          pointerEvents: authenticatedUser.emailVerified ? 'auto' : 'none',
                          opacity: authenticatedUser.emailVerified ? 1 : 0.5,
                         }}
                         component={Link}
                         to={settingsRouteBuilder()}>
                Settings
              </ListItem>

              {teams.length > 1 && (
                <ListItem
                  onClick={handleChangeShowTeams}
                  className="menu-customer__switch-team"
                >
                  <div>Switch teams</div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" />
                  </div>
                </ListItem>
              )}
              <ListItem
                disabled={isDisabledCreate}
                onClick={() =>
                  isDisabledCreate ||
                  (setAnchorEl(Null), navigate(createTeamRoute))
                }
                sx={{
                  cursor: isDisabledCreate ? "not-allowed" : "pointer",
                }}
              >
                <Link
                  className="create-team-link"
                  to={isDisabledCreate ? createTeamRoute : "#"}
                  title={
                    authenticatedUser.emailVerified
                      ? ""
                      : "Please verify your email address first"
                  }
                  aria-disabled={isDisabledCreate}
                >
                  Create a Team
                </Link>
              </ListItem>
              <ListItem onClick={() => logout()}>Sign Out</ListItem>
            </List>
          )}
          {showTeams && (
            <List className="menu-customer menu-customer__teams">
              <ListItem
                onClick={handleChangeShowTeams}
                className="menu-customer__back-menu"
              >
                <div>
                  <ArrowBackIosIcon fontSize="small" />
                </div>
                <div>Back</div>
              </ListItem>
              {teams.map((item) => (
                <ListItem
                  onClick={handleSwitchTeam}
                  key={item.id}
                  data-team-id={item.id}
                  className={classNames("menu-customer__team", {
                    "menu-customer__current-team": item.id === team?.id,
                  })}
                >
                  <div>{item.name}</div>
                  {item.id === team?.id && (
                    <div>
                      <DoneIcon />
                    </div>
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default AccountInfo;
