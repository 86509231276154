import { prop } from "lodash/fp";
import { getTrap } from "../axiosInstance";
import getId from "../../helpers/fp/models/getId";

const TEAM_PATH        = "teams";
const ACTUAL_COST_PATH = "actual-costs";

const actualCostApi = Object.freeze({
  getByTeam: (team) => getTrap(`${TEAM_PATH}/${getId(team)}/${ACTUAL_COST_PATH}`).then(prop("data")),
});

export default actualCostApi;
