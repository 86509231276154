/*eslint-disable*/

import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import teamService from "../../../api/team";

export const teamMembersQueryOptions = (teamId) => queryOptions({
  queryKey: ["team-members", {
    teamId
  }],
  queryFn: async () => {
    const { data } = await teamService.getTeamMembers(teamId);

    return data;
  },
});

const useTeamMembersQuery = ({
                        enabled = true,
                        teamId
                      }) => {
  const { data, isFetching, isPending, error, isError, refetch } = useQuery({
    ...teamMembersQueryOptions(teamId),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: [],
    placeholderData: keepPreviousData
  })

  return {
    teamMembers: data,
    isEmpty: !data.lendth,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending
  }
}

export default useTeamMembersQuery;
