import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import appService from "../../../api/app";

export const appsQueryKey = "apps";

export const appsQueryOptions = ({ teamId, projectId }) => queryOptions({
  queryKey: [appsQueryKey, {
    teamId,
    projectId
  }],
  queryFn: async () => {
    const { data } = await appService.getApps({ teamId, projectId });

    return data;
  },
});

const useAppsQuery = ({
                        enabled = true,
                        teamId,
                        projectId
                      }) => {
  const {
    data,
    isFetching,
    isPending,
    error,
    isError,
    refetch
  } = useQuery({
    ...appsQueryOptions({
      teamId,
      projectId
    }),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: [],
    placeholderData: keepPreviousData
  });

  return {
    items: data,
    isEmpty: data && !data?.length,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending,
  };
};

export default useAppsQuery;
