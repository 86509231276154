import { Visibility, VisibilityOff } from "@mui/icons-material";
import React from "react";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const EyeIconButton = ({
                         value,
                         onClick,
                         disabled,
                         size = "small",
                       }) => {
  return (
    <Tooltip title={value ? "Hide" : "Show"} arrow>
      <span>
        <IconButton size={size} onClick={onClick} disabled={disabled}>
          {value ? <Visibility fontSize={size}/> : <VisibilityOff fontSize={size}/>}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default EyeIconButton;
