import React from 'react';
import { Box, Button, Card, Chip, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { projectsRoute } from "../../constants/routes/routes";

const TeamItem = ({ team, isCurrent, disabled, onSelect }) => {
  return (
    <Card key={team.id}
          variant="outlined"
          square
          sx={{
            paddingInline: "1rem",
            paddingBlock: "0.5rem",
            backgroundColor: "#fff",
            color: "#000000"
          }}>
      <Stack direction="row" spacing={2} sx={{
        alignItems: "center",
        justifyContent: "space-between",
      }}>
        <Box>
          <Typography>{team.name}</Typography>
          {
            team.email && <Typography fontSize={14} color="primary">{team.email}</Typography>
          }
        </Box>

        {isCurrent ? (
          <Stack sx={{
            alignItems: "center",
          }} spacing={2}
                 direction="row">
            <Chip label="Current" size="small"/>
            <Button variant="contained"
                    component={Link}
                    disabled={disabled}
                    to={projectsRoute}
                    sx={{
                      width: "8rem",
                      textAlign: "center"
                    }}
            >
              Open
            </Button>
          </Stack>
        ) : (
          <Button variant="outlined"
                  sx={{
                    width: "8rem",
                  }}
                  disabled={disabled}
                  onClick={() => onSelect(team)}>
            Select
          </Button>
        )}
      </Stack>
    </Card>
  )
}

export default TeamItem;
