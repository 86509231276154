import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import { IconLogo } from "../../../assets/icons";

import "./style.css";

// hooks
import useCurrentProject from "../../../providers/MetadataProvider/hooks/useCurrentProject";

// helpers
import concatPaths from "../../../helpers/fp/url/concatPaths";

import { projectsRoute } from "../../../constants/routes/routes";
import useThemesQuery from "../../../modules/themes/queries/useThemeQuery";

const LogoImage = ({ logoUrl }) => {
  if (!logoUrl) {
    return (
      <IconLogo/>
    )
  }

  if (logoUrl.endsWith(".svg")) {
    return (
      <ReactSVG src={logoUrl}
                onError={(error) => {
                  console.error(error)
                }}
                beforeInjection={(svg) => {
                  svg.setAttribute('fill', 'var(--color-white)')
                }}
                httpRequestWithCredentials
                wrapper="div"
      />
    )
  }

  return (
    <img
      style={{ objectFit: "contain" }}
      src={logoUrl}
      alt="logo"
      className="logo"
    />
  )
}

const BrandLogo = ({
                className,
                ...props
              }) => {
  const { data: theme } = useThemesQuery();

  const currentProject = useCurrentProject();

  return (
    <Box
      component={Link}
      to={concatPaths([projectsRoute, currentProject?._id])}
      aria-label="go to project"
      sx={{
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center'
      }}
      {...props}
      className={classNames("logo-wrapper", className)}
    >
      <LogoImage logoUrl={theme?.logoUrl}/>
    </Box>
  );
};

export default BrandLogo;
