/*eslint-disable*/

import { Button, Card, Stack } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useAuth from "../../../../../providers/AuthProvider/useAuth";
import { isTeamOwner } from "../../../../../modules/team/helpers";

const TeamInfo = ({ team, onEdit }) => {
  const { authenticatedUser } = useAuth();

  return (
    <Box>
      <Card sx={{
        backgroundColor: "#fff",
        color: "#000000",
        padding: "1rem"
      }} variant="outlined" square>
        <Stack direction="row"
               spacing={2}
               sx={{
                 justifyContent: "space-between",
                 alignItems: "center",
               }}>
          <Stack>

            <Typography variant="h3">
              {team.name}
            </Typography>
            <Typography variant="h6" color="primary">
              {team.email}
            </Typography>
          </Stack>

          {isTeamOwner(team, authenticatedUser) && (
            <Button onClick={() => onEdit(team)} variant="contained">
              Edit
            </Button>
          )}
        </Stack>
      </Card>
    </Box>
  )
}

export default TeamInfo
