// AXIOS
import concatPaths from "../../helpers/fp/url/concatPaths";
import { getTrap } from "../axiosInstance";
import { SIZES_PATH } from "../size";

/**
  * @param {string} sizeId
  * @return {Promise<import("axios").AxiosResponse<import("../../types/Price").default>>}
  */
const getPriceBySizeId = (sizeId) => getTrap(concatPaths([SIZES_PATH, sizeId, "price"]));

const priceApi = Object.freeze({
  getPriceBySizeId,
});

export default priceApi;
