/*eslint-disable*/

import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import DialogBase from "../../../../../components/shared/DialogBase/DialogBase";
import FormikTextField from "../../../../../components/shared/Formik/FormikTextField";
import useTeamQuery from "../../../../../modules/team/queries/useTeamQuery";
import useDeferredPromise from "../../../../../hooks/useDeferredPromise";
import { Alert, Stack } from "@mui/material";
import useUpdateTeamMutation from "../../../../../modules/team/queries/useUpdateTeamMutation";

const validationSchema = Yup.object({
  name: Yup
    .string()
    .required("Name is required"),
  email: Yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

const EditTeamModalDialog = forwardRef((props, ref) => {
  const { defer, deferRef } = useDeferredPromise();

  const [initialization, setInitialization] = useState(false);
  const [open, setOpen] = useState(false);
  const [openProps, setOpenProps] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: (openProps) => {
        if (!openProps.teamId) {
          console.error("EditTeamModalDialog: teamId is required!")
          return;
        }
        setOpenProps(openProps);
        setInitialization(true);
        return defer().promise;
      },
    }),
    [],
  );

  const teamQuery = useTeamQuery({
    enabled: initialization && !!openProps?.teamId,
    teamId: openProps?.teamId
  });

  const updateTeamMutation = useUpdateTeamMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: ""
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async ({ name, email }, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await updateTeamMutation.mutateAsync({
          teamId: openProps?.teamId,
          payload: {
            name,
            email
          }
        })
        toast.success("Team details were updated!");
        handleClose(true);
      } catch (e) {
        toast.error("Something went wrong, please try again later!");
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (initialization) {
      if (teamQuery.isSuccess) {

        formik.setValues({
          name: teamQuery.team.name,
          email: teamQuery.team.email,
        })

        setOpen(true);
      } else {
        toast.error("Something went wrong, please try again later!");

        // eslint-disable-next-line no-use-before-define
        handleClose(false);
      }
    }
  }, [initialization, teamQuery.isSuccess])

  const handleClose = (success = false) => {
    setOpen(false);
    setInitialization(false);
    formik.resetForm();

    deferRef.resolve(success);
  };

  if (initialization && teamQuery.isPending) {
    return (
      <CircularProgress fullWidth/>
    )
  }

  return (
    <DialogBase.Root onClose={handleClose} open={open} title="Edit team info" maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <DialogBase.Content>
          <Stack spacing={2}>
            <FormikTextField formik={formik}
                             name="name"
                             label="Name"
                             disabled={formik.isSubmitting}
                             variant="outlined"
                             fullWidth
                             size="medium"
                             style={{ marginTop: "0.5rem" }}
            />
            <Alert severity="info">Important operational emails are sent to this email address.</Alert>
            <FormikTextField formik={formik}
                             name="email"
                             label="Email"
                             disabled={formik.isSubmitting}
                             variant="outlined"
                             fullWidth
                             size="medium"
            />
          </Stack>
        </DialogBase.Content>
        <DialogBase.Actions>
          <LoadingButton type="submit"
                         disabled={!formik.isValid || !formik.dirty}
                         autoFocus
                         fullWidth
                         loading={formik.isSubmitting}
                         variant="contained">
            Update
          </LoadingButton>
        </DialogBase.Actions>
      </form>
    </DialogBase.Root>
  );
});

export default EditTeamModalDialog;
