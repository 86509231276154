import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userAccountQueryKey } from "./useUserAccountQuery";
import { authCurrentUserQueryKey } from "../../auth/queries/useAuthCurrentUserQuery";
import accountAPI from "../../../api/account";

const useDeleteUserAccountCredentialMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutate, mutateAsync } = useMutation({
    mutationFn: ({ payload }) => {
      return accountAPI.deleteAccountCredential(payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [authCurrentUserQueryKey]
      })
      await queryClient.invalidateQueries({
        queryKey: [userAccountQueryKey]
      })
    }
  });

  return {
    isPending,
    mutate,
    mutateAsync,
  };
};

export default useDeleteUserAccountCredentialMutation;
