/* eslint-disable */

import React from "react";
import { Box } from "@mui/material";

const RowContainer = ({ children }) => {
  return (
    <Box sx={{ minHeight: "2.4rem", display: "flex", alignItems: "center" }}>
      {children}
    </Box>
  )
}

export default RowContainer
