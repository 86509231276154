import isPast from "../../../../../helpers/fp/date/isPast";

const validatePaymentMethodCard = (paymentMethod) => {
  const {
    exp_month : expMonth,
    exp_year  : expYear,
  } = paymentMethod.card;

  if (!expMonth || !expYear) throw new Error("Your card was declined.")
  if (isPast(new Date(expYear, expMonth))) {
    throw new Error("Your card was expired.")
  }

  return true;
};

export default validatePaymentMethodCard;
