/* eslint-disable */

import React from "react";
import ContextMenuBase from "../../../../../../../components/shared/ContextMenuBase";

const TeamMemberContextMenu = ({
                                 teamMember,
                                 isAuthenticatedUserTeamMember,
                                 isAuthenticatedUserTeamMemberOwner,
                                 onDelete,
                                 onLeave
                               }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    setAnchorEl(null);

    /* eslint-disable-next-line */
    action && action(teamMember);
  };

  return (
    <>
      <ContextMenuBase.TriggerButton
        open={open}
        handleOpen={handleOpen}
      />
      <ContextMenuBase.Root
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          isAuthenticatedUserTeamMemberOwner ? (
            <ContextMenuBase.Item key={0} onClick={() => handleAction(onDelete)}>
              Delete
            </ContextMenuBase.Item>
          ) : (
            <ContextMenuBase.Item key={1} onClick={() => handleAction(onLeave)}>
              Leave
            </ContextMenuBase.Item>
          )
        }
      </ContextMenuBase.Root>
    </>
  );
};

export default TeamMemberContextMenu;
