import { useMutation, useQueryClient } from "@tanstack/react-query";
import teamService from "../../../api/team";
import { teamMembersQueryOptions } from "./useTeamMembersQuery";

const useDeleteTeamMemberMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutate, mutateAsync } = useMutation({
    mutationFn: ({ teamId, userId }) => {
      return teamService.deleteTeamMember({
        teamId,
        userId
      })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(teamMembersQueryOptions(variables.teamId));
    }
  });

  return {
    isPending,
    mutate,
    mutateAsync,
  };
};

export default useDeleteTeamMemberMutation;
