/* eslint-disable */

// CORE
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";

import { useDispatch } from "react-redux";

// MUI
import { Box } from "@mui/material";

// STYLES
import "./dashboardLayout.scss";

// CONSTANTS
import { notificationTypes } from "../../../constants/notification";

// STORE
import notificationStore from "../../../store/notification";

// ROUTES
import DashboardHeader    from "./components/DashboardHeader/DashboardHeader";
import DashboardOutlet    from "./components/DashboardOutlet/DashboardOutlet";
import DashboardSideBar   from "./components/DashboardSideBar/DashboardSideBar";
import DeactivateTip      from "./components/DeactivateTip";
import SuspendTip         from "./components/SuspendTip";
import SwitchTeam         from "./components/SwitchTeam";
import InvoiceFailedModal from "../../shared/InvoceFailedModal";
import InvoicePaidModal   from "../../shared/InvocePaidModal";
import useCurrentTeam         from "../../../providers/MetadataProvider/hooks/useCurrentTeam";
import { isTeamSuspended, isTeamOwner } from "../../../modules/team/helpers";
import { isUserDeactivated } from "../../../modules/user/helpers";
import useAuth from "../../../providers/AuthProvider/useAuth";

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const { team } = useCurrentTeam();

  const [nextTeam, setNextTeam] = useState(null);
  const [currentNotification, setCurrentNotification] = useState(null);

  const authenticatedUserId = authenticatedUser?.id;

  useLayoutEffect(() => {
    if (authenticatedUserId)
      dispatch(
        notificationStore.asyncActions.getNotifications({ page: 1, limit: 10 })
      );
  }, [authenticatedUserId]);

  const handleCloseModal = useCallback(() => setCurrentNotification(null), []);

  return (
    <Box className="dashboard-layout">
      <DashboardHeader
        setNextTeam={setNextTeam}
        setCurrentNotification={setCurrentNotification}
        className="dashboard-header"
      />
      <DashboardSideBar
        className="dashboard-side-bar"
      />
      <Box
        id="dashboard-wrapper"
        className="dashboard-wrapper"
        display="flex"
        flexDirection="column"
        height="100%"
        paddingTop="var(--offset-2)"
        paddingInline="var(--offset)"
        paddingBottom="var(--offset)"
      >
        { isTeamSuspended(team) && <SuspendTip isTeamOwner={isTeamOwner(team, authenticatedUser)}/> }
        { isUserDeactivated(authenticatedUser) && <DeactivateTip isTeamOwner={isTeamOwner(team, authenticatedUser)}/>}
        <DashboardOutlet />
      </Box>
      <SwitchTeam
        setNextTeam={setNextTeam}
        nextTeam={nextTeam}
      />
      {
        {
          [notificationTypes.invoicePaid]: (
            <InvoicePaidModal
              notification={currentNotification}
              onClose={handleCloseModal}
            />
          ),
          [notificationTypes.invoiceFailed]: (
            <InvoiceFailedModal
              notification={currentNotification}
              onClose={handleCloseModal}
            />
          ),
        }[currentNotification?.type]
      }
    </Box>
  );
};

export default DashboardLayout;
