import { Stack } from "@mui/material";
import { Info } from "@mui/icons-material";
import React from "react";

const SmallTooltip = ({ children }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{
      fontSize: "0.75rem"
    }}>
      <Info sx={{
        fontSize: "22px",
      }} color="gray"/>
      <span>{ children }</span>
    </Stack>
  )
}

export default SmallTooltip
