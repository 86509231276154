import { keepPreviousData, useQuery } from "@tanstack/react-query";
import accountAPI from "../../../api/account";

export const userAccountSessionsQueryKey = "user-account-sessions";

const useUserAccountSessionsQuery = ({
                                       enabled = true,
                                       userId,
                                     }) => {
  const { data, isPending, isFetched, isError, refetch } = useQuery({
    queryKey: [userAccountSessionsQueryKey, { userId }],
    queryFn: async () => {
      const { data } = await accountAPI.getAccountSessions();
      return data;
    },
    placeholderData: keepPreviousData,
    initialData: [],
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    sessions: data ?? [],
    isPending,
    isFetched,
    isError,
    refetch
  }
};

export default useUserAccountSessionsQuery;
