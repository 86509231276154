/* eslint-disable */

import React from "react";
import { Box, Button } from "@mui/material";
import RowContainer from "../shared/RowContainer";
import StructuredRow from "../shared/StructuredRow";
import useAuth from "../../../../providers/AuthProvider/useAuth";
import { SIGN_IN_METHODS } from "../../../../modules/account/constants";
import { getUserCredential } from "./helpers";

const PasswordRow = ({ user, isPending, onSetDefault }) => {
  const { updatePassword } = useAuth();

  const userCredential = getUserCredential(user.credentials, SIGN_IN_METHODS.PASSWORD);

  const handleSetDefault = () => {
    onSetDefault(userCredential)
  }

  return (
    <RowContainer>
      <Box sx={{
        width: "100%",
      }}>
        <StructuredRow title="Password"
                       isDefault={userCredential?.isDefault}
                       actions={
                         <>
                           {!userCredential?.isDefault && (
                             <Button variant="outlined"
                                     disabled={isPending}
                                     onClick={handleSetDefault}>
                               Set as default
                             </Button>
                           )}
                           <Button variant="outlined"
                                   disabled={isPending}
                                   onClick={updatePassword}>
                             Reset
                           </Button>
                         </>
                       }/>
      </Box>
    </RowContainer>
  )
}

export default PasswordRow
