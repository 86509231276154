/* eslint-disable */

import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { getDayjsLocalTime } from "../../../../../helpers/date-time/getLocalTime";

const SessionsTableRow = ({
                            number,
                            session,
                          }) => {


  return (
    <TableRow>
      <TableCell>
        {number}
      </TableCell>
      <TableCell>
        {session.ipAddress}
      </TableCell>
      <TableCell>
        {session.rememberMe ? "Yes" : "No"}
      </TableCell>
      <TableCell>
        {getDayjsLocalTime(session.start).format("LLL")}
      </TableCell>
      <TableCell>
        {getDayjsLocalTime(session.lastAccess).format("LLL")}
      </TableCell>
    </TableRow>
  )
}

export default SessionsTableRow;
