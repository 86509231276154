import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import serverModeStore from "../../../store/serverMode";
import { serverModeStatuses, serverModeTypes } from "../../../constants/serverMode";

const useServerMode = (enabled = true) => {
  const dispatch = useDispatch();
  const modes = useSelector((state) => state.serverMode.items);
  const isError = useSelector((state) => state.serverMode.isError);
  const isFetched = useSelector((state) => state.serverMode.isFetched);
  const isLoading = useSelector((state) => state.serverMode.isLoading);

  const maintenanceMode = useMemo(
    () => modes.find((mode) => mode.type === serverModeTypes.maintenance),
    [modes]
  );
  const waitingListMode = useMemo(
    () => modes.find((mode) => mode.type === serverModeTypes.waitinglist),
    [modes]
  );

  useEffect(() => {
    if (enabled && !isLoading) {
      dispatch(serverModeStore.asyncActions.getServerModes());
    }
  }, [enabled]);

  return useMemo(
    () => ({
      isLoading,
      isFetched,
      isError,
      isMaintenanceMode: (!!maintenanceMode && maintenanceMode.status === serverModeStatuses.off) || isError,
      isWaitingListMode: !!waitingListMode && waitingListMode.status === serverModeStatuses.off
    }),
    [isLoading, isError, isFetched, maintenanceMode, waitingListMode]
  );
};

export default useServerMode;
