// AXIOS
import { z } from "zod";
import getId from "../../helpers/fp/models/getId";
import axiosInstance, { getTrap } from "../axiosInstance";
import databaseCreateSchema from "../../modules/database/schemas/create";

const getDatabases   = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/databases`);
const getDatabase    = (dbId) => getTrap(`/databases/${dbId}`);
const deleteDatabase = (databaseId) => axiosInstance.delete(`/databases/${databaseId}`);

const createDatabase = z
  .function()
  .args(databaseCreateSchema)
  .strictImplement((databases) => axiosInstance.post(`/databases`, databases))
;

const getDatabasesByTeam    = (team)    => getTrap(`/teams/${getId(team)}/databases`);
const getDatabasesByProject = (project) => getTrap(`/projects/${getId(project)}/databases`);

const databaseApi = Object.freeze({
  getDatabases,
  getDatabase,
  deleteDatabase,
  createDatabase,

  getByTeam    : getDatabasesByTeam,
  getByProject : getDatabasesByProject
});

export default databaseApi;
