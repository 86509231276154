import { useContext } from "react";
import MetadataContext from "../../../providers/MetadataProvider/MetadataContext";

const useProjects = () => {
  const { project: currentProject, projects, selectProject } = useContext(MetadataContext);

  return {
    projects,
    currentProject,
    selectProject,
  }
}

export default useProjects;
