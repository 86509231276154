// CORE
import React from "react";
// ASSETS
import providerImage from "../../assets/images/providers.webp";
// STYLES
import "./maintenance.scss";

const Maintenance = () => {
  setInterval(() => window.location.reload(), 10000);

  return (
    <div className="maintenance-page">
      <h2 className="maintenance-page__title">Maintenance mode</h2>
      <p className="maintenance-page__subtitle">
        Our website is currently undergoing scheduled maintenance. Sorry for
        the inconvenience.
      </p>
      <img
        src={providerImage}
        className="maintenance-page__img"
        alt="Providers"
      />
    </div>
  );
};

export default Maintenance;
