import { MoreVert } from "@mui/icons-material";
import { Divider, Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { Link } from "react-router-dom";

const ContextMenuRoot = ({
                           children,
                           open,
                           onClose,
                           anchorEl,
                         }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      autoFocus={false}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          background: "#ffffff",
          padding: "4px",
          mt: 1.5,
          "&::before": {
            content: "\"\"",
            display: "block",
            position: "absolute",
            top: 0,
            left: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) translateX(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      {children}
    </Menu>
  );
};

const ContextMenuDivider = () => {
  return (
    <Divider/>
  );
};

const ContextMenuTriggerButton = ({ open, handleOpen, disabled }) => {
  return (
    <IconButton
      disabled={disabled}
      size="small"
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleOpen}
    >
      <MoreVert/>
    </IconButton>
  );
};

const ContextMenuLinkItem = ({
                           disabled,
                           children,
                           to,
                         }) => {
  return (
    <MenuItem disabled={disabled} component={Link} to={to}>
      {children}
    </MenuItem>
  );
};

const ContextMenuBase = {
  Root: ContextMenuRoot,
  Item: MenuItem,
  LinkItem: ContextMenuLinkItem,
  Divider: ContextMenuDivider,
  TriggerButton: ContextMenuTriggerButton,
};

export default ContextMenuBase;
