/* eslint-disable */

// CORE
import { Badge, Box, Chip } from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import React, { useCallback, useEffect } from "react";
import './styles.scss';
import { useNavigate } from "react-router-dom";
import useAuthenticatedUserTeams from "../../../../../modules/team/hooks/useAuthenticatedUserTeams";
import { projectsRoute } from "../../../../../constants/routes/routes";
import getId from "../../../../../helpers/fp/models/getId";
import { toast } from "react-toastify";

const SwitchTeam = ({ nextTeam, setNextTeam }) => {
  const {currentTeam, selectTeam, selectTeamIsPending } = useAuthenticatedUserTeams();

  const navigate = useNavigate();

  const handleSwitchTeam = useCallback(async () => {
    if (!nextTeam || selectTeamIsPending) return;

    try {
      await selectTeam(getId(nextTeam));
      navigate(projectsRoute)
    } catch (e) {
      toast.error("Something went wrong, please try again later!");
      console.log(e);
    } finally {
      setNextTeam(null);
    }
  }, [nextTeam])

  useEffect(() => {
    handleSwitchTeam();
  }, [nextTeam])

  if (!selectTeamIsPending) return null;

  return (
    <Box className="switch-window">
      <Box className="switch-window__content">
        <h4 className="switch-window__title">Switching teams...</h4>
        <Box className="switch-window__teams">
          <Badge
            sx={{ marginLeft: '16px' }}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Chip label={currentTeam?.name}/>
          </Badge>
          <TrendingFlatIcon/>
          <Badge
            sx={{ marginLeft: '16px' }}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Chip label={nextTeam?.name}/>
          </Badge>
        </Box>
      </Box>
    </Box>
  );
};

export default SwitchTeam;
