import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import React from "react";

const ExpandIconButton = ({ open, disabled, onClick }) => {
  return (
    <IconButton
      aria-label="expand row"
      size="small"
      disabled={disabled}
      onClick={onClick}
    >
      {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
    </IconButton>
  )
}

export default ExpandIconButton;
