import React from "react";
import { Chip, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

const StructuredRow = ({ title, isDefault, content, actions }) => {
  return (
    <Grid container sx={{ width: "100%", alignItems: "center" }}>
      <Grid item xs={3}>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <Typography variant="body2" fontWeight="bold" noWrap>{title}</Typography>
          {isDefault && <Chip size="small" label="default"/>}
        </Stack>
      </Grid>
      <Grid item xs>
        <Typography variant="body2">
          {content}
        </Typography>
      </Grid>
      {actions && (
        <Grid item xs sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            {actions}
          </Stack>
        </Grid>
      )}
    </Grid>

  )
}

export default StructuredRow;
