/* eslint-disable */

import React, { useRef } from "react";
import { Box, Chip, Divider, Stack } from "@mui/material";
import DeactivateRow from "./Profile/DeactivateRow";
import NameRow from "./Profile/NameRow";
import PasswordRow from "./Security/PasswordRow";
import PasskeysRow from "./Security/PasskeysRow";
import OTPRow from "./Security/OTPRow";
import EmailRow from "./Profile/EmailRow";
import AlertDialog from "../../../components/shared/AlertDialog/AlertDialog";
import useDeleteUserAccountCredentialMutation
  from "../../../modules/account/queries/useDeleteUserAccountCredentialMutation";
import { toast } from "react-toastify";
import useSetDefaultUserAccountCredentialMutation
  from "../../../modules/account/queries/useSetDefaultUserAccountCredentialMutation";

const ProfileTab = ({ user }) => {
  const alertDialog = useRef();

  const deleteUserAccountSignInMethodMutation = useDeleteUserAccountCredentialMutation();
  const setDefaultUserAccountCredentialMutation = useSetDefaultUserAccountCredentialMutation();

  const handleDeleteOTP = async (method) => {

    await alertDialog.current.open({
      title: "Are you sure you want to delete 2FA sign-in method?",
      confirmButtonText: "Confirm",
      confirmCallback: async () => {
        try {
          await deleteUserAccountSignInMethodMutation.mutateAsync({
            payload: {
              credentialId: method.id
            }
          });

          toast.success("2FA sign-in method was deleted successfully!");
        } catch (e) {
          console.error(e);
          toast.success("Something went wrong, please try again later!");
        }
      },
    });
  }

  const handleDeletePasskeys = async (method) => {
    await alertDialog.current.open({
      title: "Are you sure you want to delete passkeys sign-in method?",
      confirmButtonText: "Confirm",
      confirmCallback: async () => {
        try {
          await deleteUserAccountSignInMethodMutation.mutateAsync({
            payload: {
              credentialId: method.id
            }
          });

          toast.success("Passkeys sign-in method were deleted successfully!")
        } catch (e) {
          console.error(e);
          toast.success("Something went wrong, please try again later!");
        }
      },
    });
  }

  const handleSetDefault = async (credential) => {
    try {
      await setDefaultUserAccountCredentialMutation.mutateAsync({
        payload: {
          credentialId: credential.id
        }
      });

      toast.success("Default sign-in method was set successfully!");
    } catch (e) {
      console.error(e);
      toast.success("Something went wrong, please try again later!");
    }
  }

  return (
    <Box>
      <Stack spacing={2} divider={<Divider flexItem/>}>
        <NameRow user={user}/>
        <EmailRow user={user}/>
      </Stack>

      <Divider>
        <Chip label="Sign-in methods" size="small"/>
      </Divider>

      <Stack spacing={2} divider={<Divider flexItem/>}>
        <PasswordRow
          user={user}
          isPending={setDefaultUserAccountCredentialMutation.isPending}
          onSetDefault={handleSetDefault}
        />
        <PasskeysRow
          user={user}
          isPending={setDefaultUserAccountCredentialMutation.isPending}
          onDelete={handleDeletePasskeys}
          onSetDefault={handleSetDefault}
        />
        <OTPRow
          user={user}
          isPending={setDefaultUserAccountCredentialMutation.isPending}
          onDelete={handleDeleteOTP}
        />
      </Stack>

      <Divider>
        <Chip label="Deactivate account" size="small"/>
      </Divider>

      <DeactivateRow style={{ marginTop: "0.5rem" }}/>

      <AlertDialog ref={alertDialog}/>
    </Box>
  )
}

export default ProfileTab
