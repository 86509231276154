import axiosInstance from "../axiosInstance";

/**
  * @returns {Promise<import("axios").AxiosResponse<import("../../types/CloudProvider").default[]>>}
  */
const list = () => axiosInstance.get("/cloud-providers");

const cloudProviderApi = Object.freeze({
  list,
});

export default cloudProviderApi;

