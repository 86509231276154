import isPast from "../../../helpers/fp/date/isPast";

const isTeamSuspended = (team) => {
  if (!team) {
    throw new Error("isTeamSuspended: team is not set!")
  }

  // return isPast(team.suspendedAt) || (teamIsDeactivated(team)) || (isAnySuspendedOwner(team))
  return isPast(team.suspendedAt)
}

export default isTeamSuspended;
