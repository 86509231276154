const isActive = (team) => {
  return !team.deactivatedAt && !!team.activatedAt && !team.deletedAt;
}

const teamGuard = {
  canCreateResources: (team) => {
    return isActive(team) && !team.suspendedAt;
  },
}

export default teamGuard;
