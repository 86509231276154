import {
  RESOURCE_STATUS_INDICATOR_STATUSES,
} from "../../../components/resources/shared/ResourceStatusIndicator/ResourceStatusIndicator";

export const vmStatuses = Object.freeze({
  creating: 0,
  attesting: 1,
  completed: 2,
  activated: 3,
  stopped: 4,
  deleting: 5,
  completedDelete: 6,
  deleted: 7,
  error: 8,
  stopping: 9,
  activating: 10,
});

export const vmStatusesNames = Object.freeze({
  [vmStatuses.creating]: "Creating",
  [vmStatuses.attesting]: "Attesting",
  [vmStatuses.completed]: "Activating",
  [vmStatuses.activating]: "Activating",
  [vmStatuses.activated]: "Working",
  [vmStatuses.stopping]: "Stopping",
  [vmStatuses.stopped]: "Stopped",
  [vmStatuses.deleting]: "Deleting",
  [vmStatuses.completedDelete]: "Deleting",
  [vmStatuses.deleted]: "Deleted",
  [vmStatuses.error]: "Failed",
});

export const vmStatusToResourceIndicatorStatus = {
  [vmStatuses.creating]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [vmStatuses.attesting]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [vmStatuses.completed]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [vmStatuses.activating]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [vmStatuses.activated]: RESOURCE_STATUS_INDICATOR_STATUSES.ACTIVE,
  [vmStatuses.stopped]: RESOURCE_STATUS_INDICATOR_STATUSES.STOPPED,
  [vmStatuses.stopping]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [vmStatuses.deleting]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [vmStatuses.completedDelete]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [vmStatuses.deleted]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [vmStatuses.error]: RESOURCE_STATUS_INDICATOR_STATUSES.ERROR,
};

export const loadVirtualMachineStatuses = Object.freeze([
  vmStatuses.creating,
  vmStatuses.attesting,
  vmStatuses.completed,
  vmStatuses.activating,
  vmStatuses.stopping,
  vmStatuses.deleting,
]);

export const deleteVirtualMachineStatuses = Object.freeze([
  vmStatuses.deleting,
  vmStatuses.completedDelete,
  vmStatuses.deleted,
]);

export const initialSetupVirtualMachineStatuses = [
  vmStatuses.creating,
  vmStatuses.attesting,
  vmStatuses.completed
]

export const cancellableVirtualMachineStatuses = [
  vmStatuses.creating,
  vmStatuses.attesting,
];

export const SSH_AUTH_METHODS = {
  KEYS: 0,
  PASSWORD: 1,
};

export const SSH_AUTH_METHODS_FORMATTED = {
  [SSH_AUTH_METHODS.KEYS]: "Keys",
  [SSH_AUTH_METHODS.PASSWORD]: "Password",
};

export const isVirtualMachineDeleted = virtualMachine => !!virtualMachine.deletedAt || [vmStatuses.deleted, vmStatuses.completedDelete].includes(virtualMachine.status)
