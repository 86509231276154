/* eslint-disable*/

import Keycloak from "keycloak-js";
import ENV from "../../constants/ENV";

class KeycloakService {
  kc = null;

  initiated = false;

  constructor() {}

  getRealm() {
    const hostname = window.location.hostname;
    const subDomain = hostname.split(".")[0];
    return ["localhost", "staging", "dev"].includes(subDomain) ? "console" : subDomain
  }

  async init({
               onSuccess = undefined,
               onError = undefined
  } = {}) {
    try {
      this.kc = new Keycloak({
        realm: this.getRealm(),
        clientId: "frontend-client",
        url: ENV.REACT_APP_KEYCLOAK_URL
      });

      const authenticated = await this.kc.init({
        pkceMethod: 'S256',
        // onLoad: 'login-required',
        onLoad: 'check-sso',
        checkLoginIframe: false,
      });

      this.initiated = true;

      onSuccess && onSuccess(authenticated);

      if (!authenticated) {
        this.kc.login();
      }
    } catch (e) {
      console.error(e)

      onError && onError(e)
    }
  }

  isAuthenticated() {
    return !!this.kc && !!this.kc.authenticated && !!this.kc.token;
  }

  getUserId() {
    return this.kc?.subject ?? null
  }

  getAccessToken() {
    return !!this.kc && this.kc.authenticated ? this.kc.token : "";
  }

  getAccountLink() {
    return this.kc.createAccountUrl()
  }

  refreshToken(time = 10000) {
    return this.kc.updateToken(time);
  }

  updateToken(successCallback, time = 30) {
    return this.kc.updateToken(time)
      .then(successCallback)
      .catch(this.kc.login);
  }

  configureSingInTOTPMethod() {
    return this.kc.login({
      action: "CONFIGURE_TOTP"
    })
  }

  configureSingInPasskeysMethod() {
    return this.kc.login({
      action: "webauthn-register-passwordless"
    })
  }

  updatePassword() {
    return this.kc.login({
      action: "UPDATE_PASSWORD"
    })
  }

  logout() {
    return this.kc.logout();
  }
}

const keycloakService = new KeycloakService();

export default keycloakService;
