import { paymentTypes } from "../../../constants/payment";
import validatePaymentMethodCard from "../components/BillingForm/helpers/validatePaymentMethodCard";
import billingSettingService from "../services";
import paymentMethodService from "../../paymentMethod/services";

const verifyBillingSettings = async (billingSetting) => {
  if (!billingSetting) {
    throw new Error("verifyBillingSettings: billingSettingsId is required!");
  }

  if (billingSetting.paymentType === paymentTypes.manual) {
    return false;
  }

  const [paymentMethods, invoiceSettings] = await Promise.all([
    paymentMethodService.getByBillingSetting(billingSetting),
    billingSettingService.getCustomer(billingSetting),
  ]);

  if (!paymentMethods?.length) {
    throw new Error("verifyBillingSettings: no paymentMethods found!");
  }

  if (!invoiceSettings) {
    throw new Error("verifyBillingSettings: no invoiceSettings found!");
  }

  const defaultPaymentMethod = invoiceSettings.invoice_settings?.default_payment_method;

  if (!defaultPaymentMethod) {
    throw new Error("verifyBillingSettings: no defaultPaymentMethod found!");
  }

  const paymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === defaultPaymentMethod);

  if (!paymentMethod) {
    throw new Error("verifyBillingSettings: no paymentMethod found!");
  }

  return validatePaymentMethodCard(paymentMethod);
}

export default verifyBillingSettings;
