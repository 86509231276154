import { SIGN_IN_METHODS } from "../../../../modules/account/constants";

export const getUserCredential = (userCredentials, type) => {
  const userCredential = userCredentials.find(method => method.type === type);

  if (!userCredential) {
    return null;
  }

  const isDefault = userCredentials
    .filter(method => ![SIGN_IN_METHODS.OTP].includes(method.type)) // Exclude optional credential
    .findIndex(method => method.type === type) === 0;

  const devices = userCredentials.filter(method => method.type === type);

  return {
    ...userCredential,
    devices,
    isDefault
  }
}
