/* eslint-disable */

import React, { useEffect, useState } from "react";
import SomethingWentWrongAlert from "../../components/shared/Alerts/SomethingWentWrongAlert";
import AuthContext from './AuthContext'
import useAuthCurrentUserQuery from "../../modules/auth/queries/useAuthCurrentUserQuery";
import PageLoader from "../../components/shared/PageLoader";

const AuthProvider = ({ client, children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isClientInitializationError, setIsClientInitializationError] = useState(false);

  useEffect(() => {
    const initializeClient = async () => {
      await client.init({
        onSuccess: (authenticated) => {
          setIsInitialized(true);
          setIsAuthenticated(authenticated);
          setIsClientInitializationError(false);
        },
        onError: () => setIsClientInitializationError(true)
      });
    };

    initializeClient();
  }, [])

  const authenticatedUserQuery = useAuthCurrentUserQuery({
    enabled: isInitialized && isAuthenticated,
    client,
    userId: client.getUserId()
  });

  if (isClientInitializationError) {
    return (
      <SomethingWentWrongAlert onRefresh={() => window.location.reload()}/>
    )
  }

  if (authenticatedUserQuery.isError) {
    return (
      <SomethingWentWrongAlert onRefresh={authenticatedUserQuery.refetch}/>
    )
  }

  if (authenticatedUserQuery.isPending || !authenticatedUserQuery.isFetched) {
    return (
      <PageLoader />
    )
  }

  return (
    <AuthContext.Provider value={{
      client,
      authenticatedUser: authenticatedUserQuery.data
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;
