/* eslint-disable */

import { Button, Container, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTeamQuery from "../../../modules/team/queries/useTeamQuery";
import { Link, useParams } from "react-router-dom";
import React, { useRef } from "react";
import PageLoader from "../../../components/shared/PageLoader";
import SomethingWentWrongAlert from "../../../components/shared/Alerts/SomethingWentWrongAlert";
import NotFound from "../../../components/shared/NotFound/NotFound";
import { ArrowBack } from "@mui/icons-material";
import { settingsRouteBuilder } from "../../../constants/routes/routes";
import { settingsTabs } from "../../Settings/constants";
import TeamInfo from "./components/TeamInfo/TeamInfo";
import TeamMembers from "./components/TeamMembers/TeamMembers";
import EditTeamModalDialog from "./components/Modals/EditTeamModalDialog";

const TeamItem = () => {
  const { teamId } = useParams();

  const teamQuery = useTeamQuery({
    enabled: !!teamId,
    teamId
  });

  const editTeamModalDialog = useRef();

  const handleEditTeam = async (team) => {
    await editTeamModalDialog.current?.open({
      teamId: team.id,
    })
  }

  if (teamQuery.isNotFound) {
    return (
      <NotFound text="Team is not found!"/>
    )
  }

  if (teamQuery.isError) {
    return (
      <SomethingWentWrongAlert onRefresh={teamQuery.refetch}/>
    )
  }

  if (teamQuery.isPending) {
    return (
      <PageLoader/>
    )
  }

  return (
    <Container maxWidth="lg">
      <Button size="small" variant="outlined" startIcon={<ArrowBack />} component={Link} to={settingsRouteBuilder(settingsTabs.teams)}>
        Back to teams
      </Button>

      <Typography style={{ marginTop: "1rem" }} variant="h1">Team</Typography>
      <Stack style={{ marginTop: "1rem" }} spacing={2}>
        <TeamInfo team={teamQuery.team} onEdit={handleEditTeam}/>
        <TeamMembers team={teamQuery.team}/>
        <EditTeamModalDialog ref={editTeamModalDialog}/>
      </Stack>
    </Container>
  )
}

export default TeamItem
