// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import updateMany from '../../helpers/store/updateMany';

const virtualMachineSlice = createSlice({
    name : 'virtualMachines',
    initialState : {
      items       : [],
      current     : null,
      isLoading   : true,
      error       : {
        currentVM : false
      }
    },
    reducers : {
      setCurrentVirtualMachine(state, { payload }) {
        state.current = payload;

        return state;
      },
      updateVirtualMachine(state, { payload }) {
        state.items = state.items.map(vm => vm._id === payload._id ? { ...vm, ...payload } : vm);

        if (!state.current) return state;

        if (state.current?._id === payload?._id) {
          state.current = { ...state.current, ipConfigurations: state.current?.ipConfigurations || [], ...payload };
        }

        return state;
      },
      deleteVirtualMachine(state, { payload }) {
        state.items = state.items.filter(vm => vm._id !== payload._id);

        return state;
      },
      clearError(state) {
        state.error.currentVM = false;

        return state;
      }
    },
    extraReducers : builder => builder
      .addCase(asyncActions.getVirtualMachines.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items    : updateMany(state.items, payload.data)
      }))
      .addCase(asyncActions.getVirtualMachines.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getVirtualMachines.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.getVirtualMachine.fulfilled, (state, { payload }) => {
        state.current = payload.data;
        state.isLoading = false;
      })
      .addCase(asyncActions.getVirtualMachine.pending, (state) => {
        state.error.currentVM = false;
        state.isLoading = true;
      })
      .addCase(asyncActions.getVirtualMachine.rejected, (state) => {
        state.isLoading = false;
        state.error.currentVM = true;
      })
      .addCase(asyncActions.getByTeam.fulfilled, (state, { payload }) => ({
        ...state,
        items: updateMany(state.items, payload.data)
      }))
      .addCase(asyncActions.getByProject.fulfilled, (state, { payload }) => ({
        ...state,
        items: updateMany(state.items, payload.data)
      }))
});

const virtualMachineStore = Object.setPrototypeOf(virtualMachineSlice, {
  asyncActions,
});

export default virtualMachineStore;
