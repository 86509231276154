// CORE
import React from "react";
// MUI
import { Box } from "@mui/material";
// ICONS
import { IconInfo } from "../../../../../assets/icons";
// CONSTANTS
import { isUserDeactivated } from "../../../../../modules/user/helpers";

const DeactivateTip = ({ owner, profile }) => {
  const textControl =
    profile._id === owner?._id
      ? "Your account is deactivated, please contact technical support for more information."
      : "These team is deactivated, please contact the team owner for more information.";

  const isHide = !owner || isUserDeactivated(owner);

  if (isHide || !profile) return null;

  return (
    <Box className="deactivate-tip">
      <IconInfo />
      {textControl}
    </Box>
  );
};

export default DeactivateTip;
