/* eslint-disable */
import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

const ActivitiesTab = ({ user, style }) => {

  return (
    <Box style={style}>
      <Typography variant="h2" gutterBottom>Activities</Typography>
    </Box>
  )
}

export default ActivitiesTab;
