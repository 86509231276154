const onlyLetters = /^[A-Za-z]+$/
const firstOrLastName = /^[A-Za-z\s-]+$/ // Only letters, space and hyphens (-).
const noWhitespaces = /^\S+$/
const beginsWithLetter = /^[A-Za-z]/
const endsWithLetter = /[A-Za-z]$/
const containsLettersNumbersUnderscoreHyphen = /^[A-Za-z0-9-_]*$/
const phone = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/

// Not checked below
const domain = /^[a-z0-9](([_-]{0,}[a-z0-9]){1}){1,63}((\.)[a-z0-9]{0,63}[a-z0-9-_]{0,62}){0,3}\.[a-z]{2,16}$/gi
const email = /(?=^\w[\w_.+-]{0,63}@\w[\w_.-]{0,251}\.[a-zA-Z]{2,8}$)^\w([\w_.+-]{0,1})(\w{1,31}[\w_.+-]{0,1}\w{1,31}){1,21}([_.+-]\w+){0,10}@((\w|\w[\w_.-]\w){1,125}.(\w|\w[\w_.-]\w){1,125}){1,83}$/i
const virtualMachine = /^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/

const regex = {
  domain,
  phone,
  email,
  virtualMachine,
  onlyLetters,
  firstOrLastName,
  noWhitespaces,
  beginsWithLetter,
  endsWithLetter,
  containsLettersNumbersUnderscoreHyphen
}

export default regex
