import useCurrentTeam from "./useCurrentTeam";
import useAuth from "../../AuthProvider/useAuth";
import isTeamOwner from "../../../modules/team/helpers/isTeamOwner";

const useIsCurrentTeamOwner = () => {
  const { authenticatedUser } = useAuth();
  const { team } = useCurrentTeam();

  return isTeamOwner(team, authenticatedUser);
};

export default useIsCurrentTeamOwner;
