// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import appService from '../../api/app';

const appAsyncActions = Object.freeze({
  getApps       : createAsyncThunk('get/apps', async (params) => appService.getApps(params)),
  createApp     : createAsyncThunk('post/apps', async (params) => appService.createApp(params)),
  deleteApp     : createAsyncThunk('delete/apps', async (params) => appService.deleteApp(params)),

  getByTeam     : createAsyncThunk('getAppsByTeam',    appService.getByTeam),
  getByProject  : createAsyncThunk('getAppsByProject', appService.getByProject),
});

export default appAsyncActions;
