// CORE
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// HOOKS
import useServerMode from "../modules/serverMode/hooks/useServerMode";
// CONSTANTS
import { maintenanceRoute, projectsRoute } from "../constants/routes/routes";
// COMPONENTS
import PageLoader from "../components/shared/PageLoader";
import MaintenancePage from "../pages/Maintenance/Maintenance";

const ServerModeProvider = ({ children }) => {
  const location = useLocation();

  const {
    isLoading,
    isError,
    isFetched,
    isMaintenanceMode,
  } = useServerMode();

  if (!isFetched || isLoading) {
    return (
      <PageLoader/>
    );
  }

  const shouldRedirectToMaintenance = (isError || isMaintenanceMode) && location.pathname !== maintenanceRoute;
  const shouldExitMaintenance = !isMaintenanceMode && location.pathname === maintenanceRoute;

  if (shouldRedirectToMaintenance) {
    return <Navigate to={maintenanceRoute} replace />;
  }

  if (shouldExitMaintenance) {
    return <Navigate to={projectsRoute} replace />;
  }

  if (isMaintenanceMode) {
    return (
      <Routes>
        <Route path={maintenanceRoute} element={<MaintenancePage />} />
      </Routes>
    )
  }

  return children;
};

export default ServerModeProvider;
