// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty from '../../helpers/fp/array/empty';
import updateMany from '../../helpers/store/updateMany';
import getId from '../../helpers/fp/models/getId';

const databaseSlice = createSlice({
  name : 'databases',
  initialState : {
    items     : empty(),
    current   : null,
    isLoading : true
  },
  reducers : {
    setCurrentDatabase(state, { payload }) {
      return ({
        ...state,
        current: payload
      });
    },
    updateDatabase(state, { payload }) {
      const databaseId = getId(payload);
      if (!databaseId) return state;

      const index = state.items.findIndex((database) => getId(database) === databaseId);
      return (index === -1)
        ? "_id" in payload
          ? ({
            ...state,
            items: state.items.concat(payload)
          })
          : state
        : ({
          ...state,
          items: state
            .items
            .slice(0, index)
            .concat({ ...state.items[index], ...payload }, state.items.slice(index + 1))
        })
      ;
    },
    deleteDatabase(state, { payload }) {
      const databaseId = getId(payload);
      if (!databaseId) return state;

      const index = state.items.findIndex((database) => getId(database) === databaseId);

      return (index === -1) ? state : ({
        ...state,
        items: state.items.slice(0, index).concat(state.items.slice(index + 1))
      });
    }
  },
  extraReducers : builder => builder
    .addCase(asyncActions.getDatabases.fulfilled, (state, { payload }) => ({
      ...state,
      items     : updateMany(state.items, payload.data),
      isLoading : false
    }))
    .addCase(asyncActions.getDatabases.pending, (state) => ({
      ...state,
      isLoading : true,
    }))
    .addCase(asyncActions.getDatabases.rejected, (state) => ({
      ...state,
      isLoading : false
    }))
    .addCase(asyncActions.getByTeam.fulfilled, (state, { payload }) => ({
      ...state,
      items: updateMany(state.items, payload.data),
    }))
    .addCase(asyncActions.getByProject.fulfilled, (state, { payload }) => ({
      ...state,
      items: updateMany(state.items, payload.data),
    }))
});

const databaseStore = Object.freeze({
  ...databaseSlice,
  asyncActions,
});

export default databaseStore;
