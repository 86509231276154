import {
  RESOURCE_STATUS_INDICATOR_STATUSES
} from "../../../components/resources/shared/ResourceStatusIndicator/ResourceStatusIndicator";

export const IMAGES = {
  MONGODB: "mongo",
  POSTGRES: "PostgreSQL",
  MARIADB: "MariaDB",
  REDIS: "Redis",
}

export const IMAGE_TO_PORT = {
  [IMAGES.MONGODB]: 27017,
  [IMAGES.POSTGRES]: 5432,
  [IMAGES.MARIADB]: 3306,
  [IMAGES.REDIS]: 6379,
}

export const dbStatuses = Object.freeze({
  creating: 0,
  attesting: 1,
  completed: 2,
  activated: 3,
  deleting: 4,
  completedDelete: 5,
  deleted: 6,
  error: 7,
});

export const dbStatusesNames = Object.freeze({
  [dbStatuses.creating]: "Creating",
  [dbStatuses.attesting]: "Attesting",
  [dbStatuses.completed]: "Working",
  [dbStatuses.activated]: "Working",
  [dbStatuses.deleting]: "Deleting",
  [dbStatuses.completedDelete]: "Deleting",
  [dbStatuses.deleted]: "Deleted",
  [dbStatuses.error]: "Failed",
});

export const dbStatusToResourceIndicatorStatus = {
  [dbStatuses.creating]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [dbStatuses.attesting]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [dbStatuses.completed]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [dbStatuses.activated]: RESOURCE_STATUS_INDICATOR_STATUSES.ACTIVE,
  [dbStatuses.deleting]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [dbStatuses.completedDelete]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [dbStatuses.deleted]: RESOURCE_STATUS_INDICATOR_STATUSES.IN_PROGRESS,
  [dbStatuses.error]: RESOURCE_STATUS_INDICATOR_STATUSES.ERROR,
}

export const loadDatabaseStatuses = [
  dbStatuses.creating,
  dbStatuses.attesting,
  dbStatuses.completed,
];

export const deleteDatabaseStatuses = [
  dbStatuses.deleting,
  dbStatuses.completedDelete,
  dbStatuses.deleted
];

export const initialSetupDatabaseStatuses = [
  dbStatuses.creating,
  dbStatuses.attesting,
  dbStatuses.completed
];

export const cancellableStatuses = [
  dbStatuses.creating,
  dbStatuses.attesting,
  dbStatuses.completed,
];

export const dbImagesWithUsernameAuth = [
  IMAGES.MARIADB,
  IMAGES.POSTGRES,
  IMAGES.MARIADB
];

export const isDatabaseDeleted = database => !!database.deletedAt || [dbStatuses.deleted, dbStatuses.completedDelete].includes(database.status)
