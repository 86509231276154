import { useContext } from "react";
import MetadataContext from "../../../providers/MetadataProvider/MetadataContext";
import useSelectTeamMutation from "../../user/queries/useSelectTeamMutation";
import useAuth from "../../../providers/AuthProvider/useAuth";

const useAuthenticatedUserTeams = () => {
  const { team: currentTeam, teams } = useContext(MetadataContext);
  const { authenticatedUser } = useAuth();
  const selectTeamMutation = useSelectTeamMutation();

  const selectTeam = async (teamId) => {
    return selectTeamMutation.mutateAsync({ userId: authenticatedUser.id, teamId });
  }

  return {
    currentTeam,
    teams,
    selectTeam,
    selectTeamIsPending: selectTeamMutation.isPending
  }
}

export default useAuthenticatedUserTeams;
