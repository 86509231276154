import getId from "../../../helpers/fp/models/getId";

const getCurrentProject = (projects, localStorageProjectId) => {
  if (!projects.length) {
    return null;
  }

  if (!localStorageProjectId) {
    return projects[0] ?? null;
  }

  let project = projects.find(project => getId(project) === localStorageProjectId);

  if (!project) {
    project = projects[0] ?? null;
  }

  return project;
}

export default getCurrentProject
