import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import ModalWindow from '../ModalWindow';
import onFalse from "../../../helpers/fp/function/onFalse";
import { paymentVerificationRoute } from '../../../constants/routes/routes';
import "./style.scss";
import useCurrentTeam from '../../../providers/MetadataProvider/hooks/useCurrentTeam';
import { isTeamOwner } from "../../../modules/team/helpers";
import useAuth from "../../../providers/AuthProvider/useAuth";

/**
  * @props {object} props
  * @props {boolean} props.open
  * @props {function} props.setOpen
  */
const CompleteBillingWindow = ({
  open,
  setOpen,
}) => {
  const { authenticatedUser } = useAuth();
  const { team } = useCurrentTeam();
  const onClose = onFalse(setOpen);
  const isOwner= isTeamOwner(team, authenticatedUser);
  const title= isOwner ? "Complete your billing information" : "Team access notice";

  return (
    <ModalWindow
      open={Boolean(open)}
      setOpen={setOpen}
      title={title}
    >
      <Box
        className="container-md flow"
        padding="0"
      >
        {isOwner
          ? (
            <Typography
              variant='body2'
              color="gray"
            >
              Make sure to add your billing information to order resources. If you leave this page, the resources
              information you entered will be lost
            </Typography>
          )
          : (
            <Typography
              variant='body2'
              color="gray"
            >
              Greetings team member! The team's owner hasn't set up a payment method yet. You won't be able to create a
              resource until this is resolved. Please contact the team owner to address this issue and get started.
            </Typography>
          )
        }
        <div className="pair">
          <Button
            onClick={onClose}
            variant='outlined'
            size='mediumium'
            color='primary'
          >
            {isOwner ? 'Not now' : 'Close'}
          </Button>
          {Boolean(isOwner) && (
            <Button
              component={Link}
              to={paymentVerificationRoute}
              variant="contained"
              size="medium"
              color="primary"
            >
              Add my billing information
            </Button>
          )}
        </div>
      </Box>
    </ModalWindow>
  );
};

export default CompleteBillingWindow;
