/*eslint-disable*/

import useSocket from "../../hooks/socket/useSocket";
import useTeamsQuery from "../../modules/team/queries/useTeamsQuery";
import React, { useEffect } from "react";
import { themesQueryOptions } from "../../modules/themes/queries/useThemeQuery";
import MetadataContext from "./MetadataContext";
import useAuth from "../AuthProvider/useAuth";
import isTeamMember from "../../modules/team/helpers/isTeamMember";
import PageLoader from "../../components/shared/PageLoader";
import SomethingWentWrongAlert from "../../components/shared/Alerts/SomethingWentWrongAlert";
import useProjectsQuery from "../../modules/projects/queries/useProjectsQuery";
import getCurrentProject from "./helpers/getCurrentProject";
import useVaultTokenQuery from "../../modules/vault/queries/useVaultTokenQuery";
import useLocalStorage from "../../hooks/useLocalStorage";
import getId from "../../helpers/fp/models/getId";
import useTeamBillingSettingQuery from "../../modules/billingSettings/queries/useTeamBillingSettingQuery";

const MetadataProvider = ({queryClient, children}) => {
  useSocket({ queryClient });

  useEffect(() => {
    queryClient.prefetchQuery(themesQueryOptions());
  }, []);

  const { authenticatedUser } = useAuth();
  const {value: localStorageProjectId, setValue: setLocalStorageProjectId} = useLocalStorage('project');

  const teamsQuery = useTeamsQuery({
    userId: authenticatedUser?.id,
  });

  const teams = teamsQuery.teams.filter((team) => isTeamMember(team, authenticatedUser))
  const team = teams.find(team => team.id === authenticatedUser.currentTeam);

  const projectsQuery = useProjectsQuery({
    enabled: !!team,
    teamId: team?.id,
  });

  const project = getCurrentProject(projectsQuery.projects, localStorageProjectId);

  if (project) {
    setLocalStorageProjectId(getId(project), true);
  }

  const teamBillingSettingsQuery = useTeamBillingSettingQuery({
    team: team,
  });

  useVaultTokenQuery({
    enabled: !!team,
    teamId: team?.id,
    userId: authenticatedUser?.id,
  });

  if (teamsQuery.isError) {
    return (
      <SomethingWentWrongAlert onRefresh={teamsQuery.refetch}/>
    )
  }

  if (projectsQuery.isError) {
    return (
      <SomethingWentWrongAlert onRefresh={projectsQuery.refetch}/>
    )
  }

  const teamsQueryIsPending = teamsQuery.isPending || !teamsQuery.isFetched;
  const projectsQueryIsPending = team && (projectsQuery.isPending || !projectsQuery.isFetched);
  const teamBillingSettingsIsPending = team && (teamBillingSettingsQuery.isPending || !teamBillingSettingsQuery.isFetched);

  if (teamsQueryIsPending
    || projectsQueryIsPending
    || teamBillingSettingsIsPending
  ) {
    return (
      <PageLoader/>
    )
  }

  return (
    <MetadataContext.Provider value={{
      teams,
      team,
      billingSettings: teamBillingSettingsQuery.data,
      project,
      projects: projectsQuery.projects,
    }}>
      {children}
    </MetadataContext.Provider>
  )
}

export default MetadataProvider;
