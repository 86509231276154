/* eslint-disable */

// CORE
import React from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  INVITE_PATH,
  KUBERNETES_NEW_ROUTE,
  KUBERNETES_ROUTE,
  PAYMENT_METHOD_PATH,
  SECRETS_ROUTE,
  TICKETS_ROUTE,
  appNewRoute,
  appRoute,
  billingRoute,
  createTeamRoute,
  databaseNewRoute,
  databaseRoute,
  paymentVerificationRoute,
  projectItemRoute,
  projectsNewRoute,
  projectsRoute,
  settingsRoute,
  virtualMachineNewRoute,
  virtualMachineRoute,
  teamRoute,
  selectTeamRoute,
  accountRoute,
  deactivateAccountRoute,
} from "./constants/routes/routes";

// COMPONENTS
import BasicLayout from "./components/layouts/BasicLayout/BasicLayout";
import DashboardLayout from "./components/layouts/DashboardLayout/DashboardLayout";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import NoMatch from "./pages/NoMatch/NoMatch";

// hooks
import useCurrentTeam from "./providers/MetadataProvider/hooks/useCurrentTeam";

import TeamItem from "./pages/Teams/TeamItem";
import SelectTeamPage from "./pages/SelectTeam";
import BillingProvider from "./providers/BillingProvider/BillingProvider";
import AccountPage from "./pages/Account/AccountPage";
import OnlyActiveUserAndTeamRoute from "./routes/OnlyActiveUserAndTeamRoute";
import OnlyTeamOwnerRoute from "./routes/OnlyTeamOwnerRoute";

// LAZY PAGES
const AUTH = React.lazy(() => import("./pages/Auth/Auth"));

const PaymentVerification = React.lazy(() =>
  import("./pages/PaymentVerification/PaymentVerificationContainer")
);

const AppItem = React.lazy(() => import("./pages/AppItem/AppItemContainer"));
const AppNew = React.lazy(() => import("./pages/AppNew/AppNewContainer"));
const AppsList = React.lazy(() => import("./pages/AppsList/AppsListContainer"));
const Billing = React.lazy(() => import("./pages/Billing"));

const CreateTeam = React.lazy(() => import("./pages/CreateTeam/Steps/Create")); // STEP 1
const CreateTeamAddPaymentMethod = React.lazy(() => import("./pages/CreateTeam/Steps/AddPaymentMethod")); // STEP 2
const CreateTeamInvite = React.lazy(() => import("./pages/CreateTeam/Steps/InviteMembers")); // STEP 3

const CreateProject = React.lazy(() =>
  import("./pages/CreateProject/CreateProjectContainer")
);
const DatabaseItem = React.lazy(() =>
  import("./pages/DatabaseItem/DatabaseItemContainer")
);
const DatabaseNew = React.lazy(() =>
  import("./pages/DatabaseNew/DatabaseNewContainer")
);
const DatabasesList = React.lazy(() =>
  import("./pages/DatabasesList/DatabasesListContainer")
);

const DeactivatePage = React.lazy(() =>
  import("./pages/DeactivateAccount/DeactivateAccount")
);

const ProjectItem = React.lazy(() => import("./pages/ProjectItem/ProjectItem"));
const Projects = React.lazy(() => import("./pages/Projects/ProjectsContainer"));
const Settings = React.lazy(() => import("./pages/Settings"));
const VirtualMachine = React.lazy(() =>
  import("./pages/VirtualMachineList/VirtualMachineListContainer")
);
const VirtualMachineItem = React.lazy(() =>
  import("./pages/VirtualMachineItem/VirtualMachineItemContainer")
);
const VirtualMachineNew = React.lazy(() =>
  import("./pages/VirtualMachineNew/VirtualMachineNewContainer")
);

const K8sList = React.lazy(() => import("./pages/K8sList/K8sListContainer"));
const K8sNewContainer = React.lazy(() =>
  import("./pages/K8sNew/K8sNewContainer")
);
const TicketsPage = React.lazy(() => import("./pages/Tickets"));
const TicketCreatePage = React.lazy(() => import("./pages/Tickets/Create"));
const TicketPage = React.lazy(() => import("./pages/Tickets/Id"));
const SecretsPage = React.lazy(() => import("./pages/Vault"));
const Smoke = React.lazy(() => import("./pages/Smoke"));

const AppRouter = () => {
  const location = useLocation();
  const { team } = useCurrentTeam();

  const isAtSelectTeamPage = location.pathname === selectTeamRoute || location.pathname.startsWith(createTeamRoute);

  if (!team && !isAtSelectTeamPage) {
    return <Navigate to={selectTeamRoute} replace/>;
  }

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={
          <BillingProvider>
            <DashboardLayout/>
          </BillingProvider>
        }>
          <Route index element={<Navigate replace to={projectsRoute}/>}/>
          <Route path="/smoke" element={<Smoke/>}/>

          <Route path={accountRoute} element={<AccountPage/>}/>

          <Route path={teamRoute} element={<TeamItem/>}/>

          <Route path={projectsRoute} element={<Projects/>}/>
          <Route path={projectsNewRoute} element={<CreateProject/>}/>
          <Route path={projectItemRoute} element={<ProjectItem/>}/>

          <Route path={settingsRoute} element={<Settings/>}/>

          <Route path={virtualMachineRoute} element={<VirtualMachine/>}/>
          <Route path={`${virtualMachineRoute}/:id`} element={<VirtualMachineItem/>}/>
          <Route path={virtualMachineNewRoute} element={
            <OnlyActiveUserAndTeamRoute>
              <VirtualMachineNew/>
            </OnlyActiveUserAndTeamRoute>
          }/>

          <Route path={databaseRoute} element={<DatabasesList/>}/>
          <Route path={`${databaseRoute}/:id`} element={<DatabaseItem/>}/>
          <Route path={databaseNewRoute} element={
            <OnlyActiveUserAndTeamRoute>
              <DatabaseNew/>
            </OnlyActiveUserAndTeamRoute>
          }/>

          <Route path={`${appRoute}/:id`} element={<AppItem/>}/>
          <Route path={appRoute} element={<AppsList/>}/>
          <Route path={appNewRoute} element={
            <OnlyActiveUserAndTeamRoute>
              <AppNew/>
            </OnlyActiveUserAndTeamRoute>
          }/>

          <Route path={KUBERNETES_ROUTE} element={<K8sList/>}/>
          <Route path={KUBERNETES_NEW_ROUTE} element={
            <OnlyActiveUserAndTeamRoute>
              <K8sNewContainer/>
            </OnlyActiveUserAndTeamRoute>
          }/>

          <Route path={billingRoute} element={
            <OnlyTeamOwnerRoute>
              <Billing/>
            </OnlyTeamOwnerRoute>
          }/>

          <Route path={TICKETS_ROUTE}>
            <Route index element={<TicketsPage/>}/>
            <Route path="create" element={<TicketCreatePage/>}/>
            <Route path=":ticketId" element={<TicketPage/>}/>
          </Route>
          <Route path={SECRETS_ROUTE}>
            <Route index element={<SecretsPage/>}/>
          </Route>
        </Route>
        <Route path="/" element={<BasicLayout/>}>
          <Route path={selectTeamRoute} element={<SelectTeamPage/>}/>
          <Route path={paymentVerificationRoute} element={<PaymentVerification/>}/>
          <Route path={`${paymentVerificationRoute}/:billingSettingsId`} element={<PaymentVerification/>}/>

          <Route path={createTeamRoute} element={<CreateTeam/>}/>
          <Route path={`${createTeamRoute}/:teamId`} element={<CreateTeam/>}/>
          <Route path={`${createTeamRoute}/:teamId/${PAYMENT_METHOD_PATH}`} element={<CreateTeamAddPaymentMethod/>}/>
          <Route path={`${createTeamRoute}/:teamId/${INVITE_PATH}`} element={<CreateTeamInvite/>}/>

          <Route path={deactivateAccountRoute} element={<DeactivatePage/>}/>
          {/* <Route path={NEEDS_ROUTE} element={<Needs />} /> */}
        </Route>
        <Route path="/smoke" element={<Smoke/>}/>
        <Route path="*" element={<NoMatch/>}/>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRouter;
