// CORE
import { combineReducers } from '@reduxjs/toolkit';

//     REDUCERS
import virtualMachine  from "./virtualMachine";
import attestation     from "./attestation";
import databases       from "./databases";
import apps            from "./apps";
import ticket          from "./ticket";
import invoice         from "./invoice";
import dns             from "./dns";
import cloudProvider   from "./cloudProvider";
import byos            from "./byos";
import k8s             from "./k8s";
import promo           from "./promo";
import notification    from "./notification";
import userActivity    from "./userActivity";
import providers       from "./providers";
import sizes           from "./sizes";
import prices          from "./prices";
import regions         from "./regions";
import images          from "./images";
import serverMode      from "./serverMode";
import billingAlert    from "./billingAlert";
import publicConfig    from "./publicConfig";
import actualCost      from "./actualCost";

export const rootReducer = combineReducers({
  cloudProvider   : cloudProvider.reducer,
  byos            : byos.reducer,
  providers       : providers.reducer,
  virtualMachine  : virtualMachine.reducer,
  attestation     : attestation.reducer,
  databases       : databases.reducer,
  apps            : apps.reducer,
  invoice         : invoice.reducer,
  ticket          : ticket.reducer,
  dns             : dns.reducer,
  k8s             : k8s.reducer,
  promo           : promo.reducer,
  notification    : notification.reducer,
  userActivity    : userActivity.reducer,
  sizes           : sizes.reducer,
  prices          : prices.reducer,
  regions         : regions.reducer,
  images          : images.reducer,
  serverMode      : serverMode.reducer,
  billingAlert    : billingAlert.reducer,
  publicConfig    : publicConfig.reducer,
  actualCosts     : actualCost.reducer,
});
