/* eslint-disable */

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import accountAPI from "../../../api/account";

export const userAccountQueryKey = "user-account";

const useUserAccountQuery = ({
                                   enabled = true,
                                   userId,
                                 }) => {
  const {data, isPending, isFetched, isError, refetch} = useQuery({
    queryKey: [userAccountQueryKey, {userId}],
    queryFn: async () => {
      const {data} = await accountAPI.getAccount();
      return data;
    },
    placeholderData: keepPreviousData,
    initialData: null,
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    user: data ?? null,
    isPending,
    isFetched,
    isError,
    refetch
  }
};

export default useUserAccountQuery;
