import getId from "../../../helpers/fp/models/getId";
import { databaseQueryOptions } from "../queries/useDatabaseQuery";
import { databasesQueryOptions } from "../queries/useDatabasesQuery";
import { isDatabaseDeleted } from "../../../constants/resources/database";

const getFieldsForMerge = (newDatabase) => {
  const { project, ...others } = newDatabase;
  return others
}

/**
 * @param {import("@tanstack/react-query").QueryClient} queryClient
 * @param newDatabase
 */
const updateCachedDatabase = (queryClient, newDatabase) => {
  const dbId = getId(newDatabase);

  const databaseQueryKey = databaseQueryOptions({
    id: dbId,
  }).queryKey;

  const databasesQueryKey = databasesQueryOptions({
    teamId: getId(newDatabase.team),
    projectId: getId(newDatabase.project),
  }).queryKey;

  const cachedDatabase = queryClient.getQueryData(databaseQueryKey);
  const cachedDatabases = queryClient.getQueryData(databasesQueryKey);

  const isDeleted = isDatabaseDeleted(newDatabase);

  if (cachedDatabase) {
    queryClient.setQueryData(databaseQueryKey, isDeleted ? null :
      {
        ...cachedDatabase,
        ...getFieldsForMerge(newDatabase)
      }
    );
  }

  if (cachedDatabases && !!cachedDatabases.length) {
    if (isDeleted) {
      queryClient.setQueryData(databasesQueryKey, cachedDatabases.filter(previousDB => getId(previousDB) !== getId(newDatabase)));
    } else {
      queryClient.setQueryData(databasesQueryKey, cachedDatabases.map(previousDatabase => getId(previousDatabase) === getId(newDatabase) ? {
        ...previousDatabase,
        ...getFieldsForMerge(newDatabase)
      } : previousDatabase));
    }
  }
}

export default updateCachedDatabase;
