/* eslint-disable */

import React, { useCallback, useContext, useMemo, useState } from "react";
import BillingContext from "./BillingContext";
import CompleteBillingWindow from "../../components/shared/CompleteBillingWindow";
import useCurrentTeam from "../MetadataProvider/hooks/useCurrentTeam";
import MetadataContext from "../MetadataProvider/MetadataContext";
import verifyBillingSettings from "../../modules/billingSettings/helpers/verifyBillingSettings";

const BillingProvider = ({ children }) => {
  const { team } = useCurrentTeam();

  const { billingSettings } = useContext(MetadataContext)

  const [isOpenCompleteBillingWindow, setIsOpenCompleteBillingWindow] = useState(false);

  const verifyBilling = useCallback(async (onSuccess = null) => {
    if (!billingSettings) {
      setIsOpenCompleteBillingWindow(true);
      return false;
    }

    try {
      await verifyBillingSettings(billingSettings)
      setIsOpenCompleteBillingWindow(false);
      onSuccess && onSuccess();
      return true;
    } catch (errorVerify) {
      console.error(errorVerify);
      setIsOpenCompleteBillingWindow(true);
      return false;
    }
  }, [team, billingSettings]);

  const value = useMemo(() => ({
    verifyBilling,
  }), [verifyBilling])

  return (
    <BillingContext.Provider value={value}>
      <CompleteBillingWindow
        open={isOpenCompleteBillingWindow}
        setOpen={setIsOpenCompleteBillingWindow}
      />
      {children}
    </BillingContext.Provider>
  )
}

export default BillingProvider
