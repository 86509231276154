import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import databaseApi from "../../../api/database";

export const databasesQueryKey = "databases";

export const databasesQueryOptions = ({ teamId, projectId }) => queryOptions({
  queryKey: [databasesQueryKey, {
    teamId, projectId,
  }],
  queryFn: async () => {
    const { data } = await databaseApi.getDatabases({ teamId, projectId });

    return data;
  },
});

const useDatabasesQuery = ({
                            enabled = true,
                            teamId,
                            projectId
                     }) => {
  const { data, isFetching, isPending, error, isError, refetch } = useQuery({
    ...databasesQueryOptions({
      teamId,
      projectId
    }),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: [],
    placeholderData: keepPreviousData
  })

  return {
    items: data,
    isEmpty: data && !data?.length,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending
  }
}

export default useDatabasesQuery;
