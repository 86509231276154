import { ContentCopy } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import copyToClipboard from "../../../helpers/copyToClipboard";

const CopyIconButton = ({
                          value,
                          size = "small",
                          disabled,
                        }) => {

  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCopy = async () => {
    setCopied(true);
    await copyToClipboard(value);
    setTimeout(() => setCopied(false), 2000);
  };

  const tooltip = copied ? "Copied!" : "Copy";

  return (
    <Tooltip
      open={open || copied}
      title={tooltip}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      arrow
    >
      <span>
        <IconButton size={size}
                    onClick={handleCopy}
                    disabled={copied || disabled || !value}
                    aria-label={tooltip}
        >
          <ContentCopy fontSize={size}/>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default CopyIconButton;
