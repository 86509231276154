import { useState, useEffect } from 'react';

const useLocalStorage = (key) => {
  const [value, setValue] = useState(() => localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        setValue(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  const updateLocalStorage = (newValue, silently = false) => {
    localStorage.setItem(key, newValue);

    if (!silently) {
      window.dispatchEvent(
        new StorageEvent("storage", { key, newValue })
      );
    }
  };

  return { value, setValue: updateLocalStorage };
};

export default useLocalStorage;
