import { virtualMachinesSecurityRulesQueryOptions } from "../queries/useVirtualMachineSecurityRules";
import getId from "../../../../../helpers/fp/models/getId";

const getFieldsForMerge = (newSecurityRule) => {
  return {
    status: newSecurityRule.status,
  }
}

const updateCachedVirtualMachineSecurityRule = (queryClient, newSecurityRule) => {
  queryClient.setQueryData(virtualMachinesSecurityRulesQueryOptions({ virtualMachineId: newSecurityRule.virtualMachine }).queryKey,
    cachedVirtualMachineSecurityRules => cachedVirtualMachineSecurityRules?.map(previousSecurityRule => getId(previousSecurityRule) === getId(newSecurityRule) ? {
        ...previousSecurityRule,
        ...getFieldsForMerge(newSecurityRule)
      } : previousSecurityRule) ?? []);
}

export default updateCachedVirtualMachineSecurityRule;
