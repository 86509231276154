import { Error } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";

const ErrorIcon = ({ tooltip = "Error", size = 20 }) => {
  return (
    <Tooltip title={tooltip} arrow>
      <Error sx={{ color: "#f44336", fontSize: size }}/>
    </Tooltip>
  );
};

export default ErrorIcon;
