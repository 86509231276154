import React from "react";
import RowContainer from "../shared/RowContainer";
import StructuredRow from "../shared/StructuredRow";

const EmailRow = ({ user }) => {
  return (
    <RowContainer>
      <StructuredRow title="Email" content={user.email}/>
    </RowContainer>
  )
}

export default EmailRow
