import Box from "@mui/material/Box";
import { Chip, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

const TagsArea = ({ tags, onDelete, disabled, placeholder, style }) => {
  return (
    <Paper variant="outlined" square sx={{ color: "#000000", padding: "0.5rem" }} style={style}>
      <Box sx={{ display: "flex", maxWidth: "30rem", flexWrap: "wrap", gap: "0.5rem" }}>
        {tags.length > 0 ? tags.map(tag => (
          <Chip key={tag} label={tag} disabled={disabled} onDelete={() => onDelete(tag)}/>
        )) : <Typography variant="body2" style={{ color: "#bdbdbd" }}>{placeholder ?? "Empty..."}</Typography>
        }
      </Box>
    </Paper>
  )
}

export default TagsArea;
