import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const DeleteIconButton = ({
                         tooltip = "Delete",
                         onClick,
                         disabled,
                       }) => {
  return (
    <Tooltip title={tooltip} arrow>
      <span>
        <IconButton size="small" onClick={onClick} disabled={disabled}>
          <DeleteIcon/>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default DeleteIconButton;
