/* eslint-disable */


import { Avatar, Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { getDayjsLocalTime } from "../../helpers/date-time/getLocalTime";
import getNameAbbr from "../../helpers/string/getNameAbbr";
import getFullName from "../../helpers/string/getFullName";

const UserPreview = ({ user }) => {
  return (
    <Box>
      <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
          <Avatar sx={{ width: 64, height: 64, fontSize: "1.4rem" }}>
            {getNameAbbr(
              user.firstName,
              user.lastName
            )}
          </Avatar>
        </Stack>

        <Box>
          <Typography variant="h2" fontWeight="bold">
            {getFullName(user.firstName, user.lastName)}
          </Typography>
          <Typography style={{ marginTop: "-0.4rem"}} variant="body2">
            Member since {getDayjsLocalTime(user.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}

export default UserPreview;
