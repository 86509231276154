export const settingsTabs = {
  teams: 0,
  byos: 1,
}

export const settingsTabsItems = {
  [settingsTabs.teams]: {
    value: settingsTabs.teams,
    label: "Teams",
    disabled: false
  },
  [settingsTabs.byos]: {
    value: settingsTabs.byos,
    label: "BYOS",
    disabled: false
  }
}
