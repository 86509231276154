import { Download } from "@mui/icons-material";
import React from "react";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const DownloadIconButton = ({
                              tooltip = "Download",
                              onClick,
                              size = "small",
                              disabled,
                            }) => {
  return (
    <Tooltip title={tooltip} arrow>
      <span>
        <IconButton size={size} onClick={onClick} disabled={disabled}>
          <Download fontSize={size}/>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default DownloadIconButton;
