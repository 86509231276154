// AXIOS
import axiosInstance, { getTrap } from "../axiosInstance";
import getId from "../../helpers/fp/models/getId";

const get = getTrap || axiosInstance.get;
export const TEAMS_PATH = "/teams";
const teamPath   = (teamId) => TEAMS_PATH + "/" + teamId;

const getTeams              = () => axiosInstance.get("/teams");
const updateTeam            = ({ teamId, updateData }) => axiosInstance.put(teamPath(teamId), updateData);
const deleteTeam            = ({ teamId }) => axiosInstance.delete(teamPath(teamId));
const getTeam               = ({ teamId }) => axiosInstance.get(`/teams/${teamId}`)

const getDraftTeam          = (teamId) => axiosInstance.get(`/teams/drafts/${teamId}`)
const createDraftTeam       = (payload) => axiosInstance.post(`/teams/drafts`, payload)
const updateDraftTeam       = (teamId, payload) => axiosInstance.put(`/teams/drafts/${teamId}`, payload)
const finishDraftTeam       = (teamId, payload) => axiosInstance.post(`/teams/drafts/${teamId}/finish`, payload)

const getTeamMembers        = (teamId) => axiosInstance.get(`/teams/${teamId}/members`);
const deleteTeamMember      = ({ teamId, userId }) => axiosInstance.delete(`/teams/${teamId}/members/${userId}`);
const setCurrentTeam        = (teamId) => axiosInstance.post(`/teams/${teamId}/current`);
const getVaultToken         = (teamId) => axiosInstance.get(`/teams/${teamId}/vault-token`);
const leaveTeam             = (teamId) => axiosInstance.delete(`/teams/${teamId}/leave`);
const getTeamResources      = ({ teamId }) => axiosInstance.get(teamPath(teamId) + "/resources")
const moveResources         = ({ teamId,data }) => axiosInstance.post(teamPath(teamId) + "/move-resources", data)
const getTeamQuotas         = (teamId) => axiosInstance.get(`/teams/${teamId}/quotas`);


// NOT TESTED BELOW
const getInvites            = (teamId) => axiosInstance.get(`/teams/${teamId}/invites`);
const inviteMembers         = ({ teamId, emails }) => axiosInstance.post(`/teams/${teamId}/invites`, { emails });
const getTeamById           = (team) => get(teamPath(getId(team)));
const createTaxId           = ({ teamId, data }) => axiosInstance.post(teamPath(teamId) + "/tax_ids", data);
const deleteTaxId           = ({ teamId, id }) => axiosInstance.delete(teamPath(teamId) + "/tax_ids/" + id);
const cancelInvite          = ({ teamId, inviteId }) => axiosInstance.delete(teamPath(teamId) + "/invite/" + inviteId);

const teamService = Object.freeze({
  getTeams,
  getTeam,
  getTeamMembers,
  getDraftTeam,
  createDraftTeam,
  updateDraftTeam,
  finishDraftTeam,
  getVaultToken,
  deleteTeamMember,
  leaveTeam,
  getTeamQuotas,
  setCurrentTeam,
  updateTeam,
  deleteTeam,

  // No tested
  getInvites,
  inviteMembers,
  cancelInvite,
  getTeamById,
  createTaxId,
  deleteTaxId,
  getTeamResources,
  moveResources,
});

export default teamService;
