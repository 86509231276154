/* eslint-disable */

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../api/axiosInstance";

export const authCurrentUserQueryKey = "auth-current-user";

const useAuthCurrentUserQuery = ({
                                   enabled,
                                   userId,
                                   client
                                 }) => {
  return useQuery({
    queryKey: [authCurrentUserQueryKey, {userId}],
    queryFn: async () => {
      await client.refreshToken();
      const {data} = await axiosInstance.get(`/users/current`);
      return data;
    },
    placeholderData: keepPreviousData,
    initialData: null,
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
  });
};

export default useAuthCurrentUserQuery;
