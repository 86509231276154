import { Badge, styled } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import React from "react";

export const RESOURCE_STATUS_INDICATOR_STATUSES = {
  ACTIVE: 'active',
  STOPPED: 'stopped',
  ERROR: 'error',
  BLOCKED: 'blocked',
  IN_PROGRESS: 'in-progress',
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.5s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
  '&.stopped .MuiBadge-badge': {
    backgroundColor: '#bdbdbd',
    color: '#bdbdbd',
    '&::after': {
      animation: 'none',
    },
  },
  '&.active .MuiBadge-badge': {
    backgroundColor: "var(--color-success)",
    color: "var(--color-success)",
  },
  '&.error .MuiBadge-badge': {
    backgroundColor: 'var(--color-dangery)',
    color: 'var(--color-dangery)',
    '&::after': {
      animation: 'none',
    },
  },
  '&.blocked .MuiBadge-badge': {
    backgroundColor: 'var(--color-primary-light)',
    color: 'var(--color-primary-light)',
  },
  '&.in-progress .MuiBadge-badge': {
    backgroundColor: 'var(--color-primary-light)',
    color: 'var(--color-primary-light)',
  }
}));

const ResourceStatusIndicator = ({
                                   title,
                                   status,
                                   children,
                                   className,
                                   ...props
                                 }) => {
  return (
    <Tooltip title={title} arrow style={{ display: "block" }}>
      <StyledBadge overlap="circular"
                   variant="dot"
                   className={classNames("resource-status-indicator", status, className)}
                   {...props}
      >
        {children}
      </StyledBadge>
    </Tooltip>
  )
}

export default ResourceStatusIndicator;
