import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import axiosInstance from "../../../api/axiosInstance";
import prop from "../../../helpers/fp/object/prop";

export const themesQueryOptions = () => queryOptions({
  queryKey: ["themes"],
  queryFn: () => {
    return axiosInstance.get(`/themes`).then(prop("data"));
  },
})

const useThemesQuery = () => {
  const { data, isFetching, isPending, isError } = useQuery({
    ...themesQueryOptions(),
    placeholderData: keepPreviousData,
    retry: 0,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    // Side effect, but ok
    if (data?.iconUrl) {
      const linkIcon = document.head.querySelector("link[rel=icon]");
      linkIcon.href = data?.iconUrl;
    }
  }, [data]);

  return {
    data,
    isFetching,
    isPending,
    isError,
  };
};

export default useThemesQuery;
