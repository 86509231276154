import axiosInstance from "../../../api/axiosInstance";

const createProject = (data) => axiosInstance.post(`/projects`, data)
const getProjects = ({ teamId }) => axiosInstance.get(`/teams/${teamId}/projects`)
const updateProject = ({ id, data }) => axiosInstance.put(`/projects/${id}`, data)
const updateProjectIcon = ({ id, data }) => axiosInstance.put(`/projects/${id}/icon`, data)
const getProjectResources = ({ teamId, projectId }) => axiosInstance.get(`/teams/${teamId}/projects/${projectId}/resources`)
const deleteProject = ({ teamId, projectId }) => axiosInstance.delete(`/teams/${teamId}/projects/${projectId}`)

const projectAPI = Object.freeze({
  createProject,
  getProjects,
  updateProject,
  updateProjectIcon,
  getProjectResources,
  deleteProject
});

export default projectAPI;
