import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { getDayjsLocalTime } from "../../../../../../helpers/date-time/getLocalTime";
import { DeleteIconButton } from "../../../../../../components/shared/IconButtons";

const AuthDevicesTableRow = ({
                               number,
                               device,
                               onDelete
                             }) => {


  return (
    <TableRow>
      <TableCell>
        {number}
      </TableCell>
      <TableCell>
        {device.name}
      </TableCell>
      <TableCell>
        {getDayjsLocalTime(device.createdAt).format("LLL")}
      </TableCell>
      <TableCell align="right">
        <DeleteIconButton onClick={() => onDelete(device)}/>
      </TableCell>
    </TableRow>
  )
}

export default AuthDevicesTableRow;
