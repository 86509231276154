/* eslint-disable */
import { appQueryOptions } from "../queries/useAppQuery";
import getId from "../../../helpers/fp/models/getId";
import { appsQueryOptions } from "../queries/useAppsQuery";
import { isAppDeleted } from "../../../constants/resources/app";

const getFieldsForMerge = (newApp) => {
  return Object.fromEntries(
    Object.entries({
      status: newApp.status,
      statusUpdatedAt: newApp.statusUpdatedAt,
      ipConfigurations: newApp.ipConfigurations,
      attestation: newApp.attestation,
      urlAddress: newApp.urlAddress,
      isBlocked: newApp.isBlocked,
      deletedAt: newApp.deletedAt,
      updatedAt: newApp.updatedAt
    }).filter(([_, value]) => value !== undefined)
  );
}

const updateCachedApp = (queryClient, newApp) => {
  const appId = getId(newApp);

  const appQueryKey = appQueryOptions({
    id: appId
  }).queryKey;

  const appsQueryKey = appsQueryOptions({
    teamId: getId(newApp.team),
    projectId: getId(newApp.project)
  }).queryKey;

  const cachedApp = queryClient.getQueryData(appQueryKey);
  const cachedApps = queryClient.getQueryData(appsQueryKey);

  const isDeleted = isAppDeleted(newApp);

  if (cachedApp) {
    queryClient.setQueryData(appQueryKey, isDeleted ? null :
      {
        ...cachedApp,
        ...getFieldsForMerge(newApp)
      });
  }

  if (cachedApps && !!cachedApps.length) {
    if (isDeleted) {
      queryClient.setQueryData(appsQueryKey, cachedApps.filter(previousApp => getId(previousApp) !== getId(newApp)));
    } else {
      queryClient.setQueryData(appsQueryKey, cachedApps.map(previousApp => getId(previousApp) === getId(newApp)
        ? {
          ...previousApp,
          ...getFieldsForMerge(newApp)
        } : previousApp));
    }
  }
}

export default updateCachedApp;
