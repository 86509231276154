import { Box, Button, Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SIGN_IN_METHODS } from "../../../../modules/account/constants";
import RowContainer from "../shared/RowContainer";
import StructuredRow from "../shared/StructuredRow";
import useAuth from "../../../../providers/AuthProvider/useAuth";
import AuthDevicesTable from "../shared/AuthDevicesTable/AuthDevicesTable";
import ExpandButton from "../shared/ExpandButton";
import { getUserCredential } from "./helpers";

const OTPRow = ({ user, isPending, onDelete }) => {
  const { configureSingInTOTPMethod } = useAuth();
  const [showDevices, setShowDevices] = useState(false);
  const handleToggleDevices = () => setShowDevices(value => !value);
  const userCredential = getUserCredential(user.credentials, SIGN_IN_METHODS.OTP);
  const hasDevices = !!userCredential?.devices.length;

  useEffect(() => {
    if (!hasDevices) {
      setShowDevices(false)
    }
  }, [hasDevices])

  return (
    <RowContainer>
      <Box sx={{ width: "100%" }}>
        <StructuredRow title="2FA/OTP (One time password)"
                       actions={
                         <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                           {hasDevices && (
                             <ExpandButton title={`Devices (${userCredential.devices.length})`}
                                           onClick={handleToggleDevices}
                                           show={showDevices}/>
                           )}
                           <Button variant="outlined"
                                   disabled={isPending}
                                   onClick={configureSingInTOTPMethod}>
                             Set up
                           </Button>
                         </Stack>
                       }
        />

        {showDevices && hasDevices && (
          <>
            <Divider sx={{ marginTop: "1rem" }}/>

            <Box sx={{ padding: "1rem" }}>
              <AuthDevicesTable devices={userCredential.devices} onDelete={onDelete}/>
            </Box>
          </>
        )}
      </Box>
    </RowContainer>
  )
}

export default OTPRow
