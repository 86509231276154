import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import prop from "lodash/fp/prop";
import virtualMachineAPI from "../../../api";

export const virtualMachinesSecurityRulesQueryOptions = ({
                                                           virtualMachineId,
                                            }) => queryOptions({
  queryKey: ["virtual-machine-security-rules", {
    virtualMachineId,
  }],
  queryFn: async () => {
    return virtualMachineAPI.getSecurityRules(virtualMachineId).then(prop("data"));
  },
});

const useVirtualMachinesSecurityRulesQuery = ({
                                   enabled = true,
                                   virtualMachineId,
                                 }) => {
  return useQuery({
    ...virtualMachinesSecurityRulesQueryOptions({
      virtualMachineId
    }),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: [],
    placeholderData: keepPreviousData
  })
}

export default useVirtualMachinesSecurityRulesQuery;
