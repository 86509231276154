/* eslint-disable */
import trap from "../helpers/fp/decorators/trap";

import ENV from "../constants/ENV";
import keycloakService from "../services/keycloak/index";
import axios from "axios";

const metaVersion = document.head.querySelector("meta[name=version]");
const metaDate    = document.head.querySelector("meta[name=date]");

const axiosInstance = axios.create({
  baseURL: ENV.REACT_APP_API_URL,
  mode: "no-cors",
  headers: {
    "Content-Type": "application/json",
    "EMCP-Version" : metaVersion?.content || "unknown",
    "EMCP-DATE"    : metaDate?.content || "unknown",
  },
});

axiosInstance.interceptors.request.use((config) => {
  if (keycloakService.isAuthenticated()) {
    const cb = () => {
      config.headers.Authorization = `Bearer ${keycloakService.getAccessToken()}`;
      return Promise.resolve(config);
    };
    return keycloakService.updateToken(cb);
  }else{
    return config;
  }
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.request.use(
  (config) => {
    const maintenanceKey = localStorage.getItem('maintenanceKey');
    if (maintenanceKey) {
      config.headers['X-Maintenance-Key'] = maintenanceKey;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getTrap = trap((url) => axiosInstance.get(url));
export const deleteTrap = trap((url) => axiosInstance.delete(url));

export default axiosInstance;
