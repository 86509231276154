import { useContext } from "react";
import BillingContext from "./BillingContext";

const useBillingProvider = () => {
  const context = useContext(BillingContext);

  if (!context) {
    throw new Error("useBillingProvider must be used within a BillingContext.Provider");
  }

  const {
    verifyBilling,
  } = context;

  return {
    verifyBilling,
  }
}

export default useBillingProvider;
