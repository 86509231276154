import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import AuthDevicesTableRow from "./AuthDevicesTableRow/AuthDevicesTableRow";

const AuthDevicesTable = ({
                            devices,
                            onDelete
                          }) => {
  return (
    <TableContainer component={Paper} variant="outlined" square>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>Device/sign-in method</TableCell>
            <TableCell>Created</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map((device, index) => (
            <AuthDevicesTableRow
              number={index + 1}
              device={device}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AuthDevicesTable;
