import { useMutation, useQueryClient } from "@tanstack/react-query";
import teamService from "../../../api/team";
import { teamQueryOptions } from "./useTeamQuery";

const useUpdateTeamMutation = ({
                                                 onSuccess,
                                                 onError,
                                               } = {
  onSuccess: undefined,
  onError: undefined,
}) => {
  const queryClient = useQueryClient();

  const { isPending, mutate, variables, mutateAsync } = useMutation({
    mutationFn: ({ teamId, payload }) => {
      return teamService.updateTeam({
        teamId,
        updateData: payload
      })
    },
    onSuccess,
    onError,
    onSettled: async (data, error, variables) => {
      await queryClient.invalidateQueries(teamQueryOptions(variables.teamId))
    }
  });

  return {
    isPending,
    getIsPending: (key) => isPending && variables.key === key,
    mutate,
    mutateAsync,
  };
};

export default useUpdateTeamMutation;
