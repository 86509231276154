/* eslint-disable */

import Box from "@mui/material/Box";
import useTeamMembersQuery from "../../../../../modules/teamMembers/queries/useTeamMembersQuery";
import SomethingWentWrongAlert from "../../../../../components/shared/Alerts/SomethingWentWrongAlert";
import NotFound from "../../../../../components/shared/NotFound/NotFound";
import React, { useRef, useState } from "react";
import TeamMembersTable from "./TeamMembersTable/TeamMembersTable";
import getId from "../../../../../helpers/fp/models/getId";
import Typography from "@mui/material/Typography";
import useAuth from "../../../../../providers/AuthProvider/useAuth";
import { isTeamOwner } from "../../../../../modules/team/helpers";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import InviteTeamMembersModalDialog from "../Modals/InviteTeamMembersModalDialog";
import { RefreshIconButton } from "../../../../../components/shared/IconButtons";
import { toast } from "react-toastify";
import AlertDialog from "../../../../../components/shared/AlertDialog/AlertDialog";
import useDeleteTeamMemberMutation from "../../../../../modules/teamMembers/queries/useDeleteTeamMemberMutation";
import useLeaveTeamMutation from "../../../../../modules/team/queries/useLeaveTeamMutation";
import { useNavigate } from "react-router-dom";
import { selectTeamRoute } from "../../../../../constants/routes/routes";
import useBillingProvider from "../../../../../providers/BillingProvider/useBillingProvider";
import getFullName from "../../../../../helpers/string/getFullName";

const TeamMembers = ({ team }) => {
  const navigate = useNavigate();
  const { verifyBilling } = useBillingProvider()
  const { authenticatedUser } = useAuth();
  const inviteTeamMembersModalDialog = useRef();
  const deleteMemberAlertDialog = useRef();
  const teamMembersQuery = useTeamMembersQuery({ teamId: getId(team.id) });
  const deleteTeamMemberMutation = useDeleteTeamMemberMutation();
  const leaveTeamMutation = useLeaveTeamMutation();

  const [search, setSearch] = useState("");

  const filteredTeamMembers = search
    ? teamMembersQuery.teamMembers.filter(
      (memberItem) => getFullName(memberItem.firstName, memberItem.lastName).toLowerCase().includes(search.toLowerCase()),
    )
    : teamMembersQuery.teamMembers;

  const isAuthenticatedUserTeamOwner = isTeamOwner(team, authenticatedUser);

  const handleDelete = async (teamMember) => {
    return deleteMemberAlertDialog.current.open({
      title: `Are you sure you want to delete "${getFullName(teamMember.firstName, teamMember.lastName)}" from the team?`,
      confirmButtonText: "Confirm",
      cancelButtonText: "No",
      confirmCallback: async () => {
        try {
          await deleteTeamMemberMutation.mutateAsync({
            teamId: getId(team),
            userId: getId(teamMember)
          })

          toast.success(`The "${getFullName(teamMember.firstName, teamMember.lastName)}" has been deleted from the team!`);
        } catch (error) {
          console.error(error)
          toast.error("Something went wrong. Please, try again later!");
        }
      }
    })
  }

  const handleLeave = (teamMember) => {
    return deleteMemberAlertDialog.current.open({
      title: `Are you sure you want to leave the team?`,
      confirmButtonText: "Confirm",
      cancelButtonText: "No",
      confirmCallback: async () => {
        try {
          await leaveTeamMutation.mutateAsync({
            teamId: getId(team),
          });

          navigate(selectTeamRoute);

          toast.success(`You left the team!`);
        } catch (error) {
          console.error(error)
          toast.error("Something went wrong. Please, try again later!");
        }
      }
    })
  }

  const handleInvite = (team) => verifyBilling(async () => {
    await inviteTeamMembersModalDialog.current?.open({
      teamId: team.id,
    })
  })

  if (teamMembersQuery.isPending) {
    return (
      <div>Loading...</div>
    )
  }

  if (teamMembersQuery.isError) {
    return (
      <SomethingWentWrongAlert onRefresh={teamMembersQuery.refetch}/>
    )
  }

  if (teamMembersQuery.isNotFound) {
    return (
      <NotFound text="Team is not found!"/>
    )
  }

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Members ({teamMembersQuery.teamMembers.length})
      </Typography>

      <Stack direction="row" spacing={2} sx={{
        marginBottom: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
      }}>

        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>

          <TextField
            placeholder="Search"
            size="medium"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small"/>
                </InputAdornment>
              ),
            }}
          />

          <RefreshIconButton size="medium" onClick={teamMembersQuery.refetch}/>

        </Stack>

        {
          isAuthenticatedUserTeamOwner && (
            <Button onClick={() => handleInvite(team)} variant="contained">
              Invite members
            </Button>
          )
        }
      </Stack>

      <TeamMembersTable team={team}
                        teamMembers={filteredTeamMembers}
                        onDelete={handleDelete}
                        onLeave={handleLeave}
      />

      <InviteTeamMembersModalDialog ref={inviteTeamMembersModalDialog}/>
      <AlertDialog ref={deleteMemberAlertDialog}/>
    </Box>
  )
}

export default TeamMembers
