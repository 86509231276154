import { useMutation, useQueryClient } from "@tanstack/react-query";
import teamService from "../../../api/team";
import { teamInvitesQueryOptions } from "./useTeamInvitesQuery";
import { teamMembersQueryOptions } from "../../teamMembers/queries/useTeamMembersQuery";

const useTeamInviteMembersMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutate, mutateAsync } = useMutation({
    mutationFn: ({ teamId, payload }) => {
      return teamService.inviteMembers({
        teamId,
        emails: payload.emails,
      })
    },
    onSettled: async (data, error, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(teamInvitesQueryOptions(variables.teamId)),
        queryClient.invalidateQueries(teamMembersQueryOptions(variables.teamId))
      ])
    }
  });

  return {
    isPending,
    mutate,
    mutateAsync,
  };
};

export default useTeamInviteMembersMutation;
