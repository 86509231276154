import { useMutation } from "@tanstack/react-query";
import accountAPI from "../../../api/account";

const useLogoutAllUserAccountSessionsMutation = () => {
  const { isPending, mutate, mutateAsync } = useMutation({
    mutationFn: () => {
      return accountAPI.logoutAllAccountSessions();
    },
  });

  return {
    isPending,
    mutate,
    mutateAsync,
  };
};

export default useLogoutAllUserAccountSessionsMutation;
