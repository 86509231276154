/* eslint-disable */
import React, { useRef } from "react";
import useUserAccountSessionsQuery from "../../../modules/account/queries/useUserAccountSessionsQuery";
import getId from "../../../helpers/fp/models/getId";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import SessionsTable from "./SessionsTable/SessionsTable";
import ContainerWithLoader from "../../../components/shared/ContainerWithLoader";
import useLogoutAllUserAccountSessionsMutation
  from "../../../modules/account/queries/useLogoutAllUserAccountSessionsMutation";
import AlertDialog from "../../../components/shared/AlertDialog/AlertDialog";

const SessionsTab = ({ user, style }) => {
  const alertDialog = useRef();

  const userAccountSessionsQuery = useUserAccountSessionsQuery({
    userId: getId(user)
  });

  const logoutAllUserAccountSessionsMutation = useLogoutAllUserAccountSessionsMutation();

  const handleLogout = async () => {
    await alertDialog.current.open({
      title: "Are you sure you want to logout from all session including this one?",
      confirmButtonText: "Confirm",
      confirmCallback: async () => {
        try {
          await logoutAllUserAccountSessionsMutation.mutateAsync(null);
          window.location.reload();
        } catch (e) {
          console.error(e);
        }
      },
    });
  }

  return (
    <Box style={style}>
      <Typography variant="h3" gutterBottom>Active sessions</Typography>

      <ContainerWithLoader
        onRetry={userAccountSessionsQuery.refetch}
        isError={userAccountSessionsQuery.isError}
        isLoading={!userAccountSessionsQuery.isFetched || userAccountSessionsQuery.isPending}>
        <SessionsTable sessions={userAccountSessionsQuery.sessions}/>

        <Button onClick={handleLogout}
                disabled={logoutAllUserAccountSessionsMutation.isPending}
                style={{ marginTop: "1rem" }} variant="outlined">
          Logout all active sessions
        </Button>
      </ContainerWithLoader>

      <AlertDialog ref={alertDialog}/>
    </Box>
  )
}

export default SessionsTab;
