/*eslint-disable*/
import { useMutation, useQueryClient } from "@tanstack/react-query";
import teamService from "../../../api/team";
import { authCurrentUserQueryKey } from "../../auth/queries/useAuthCurrentUserQuery";

const useSelectTeamMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutate, variables, mutateAsync } = useMutation({
    mutationFn: async ({ userId, teamId }) => {
      const { data } = await teamService.setCurrentTeam(teamId);
      return data;
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [authCurrentUserQueryKey]
      })
    },
    onSettled: async (data, error, variables) => {
      // await queryClient.invalidateQueries(teamQueryOptions(variables.teamId))
    }
  });

  return {
    isPending,
    mutate,
    mutateAsync,
  };
};

export default useSelectTeamMutation;
