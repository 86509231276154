import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TeamMembersTableRow from "./TeamMembersTableRow/TeamMembersTableRow";

const TeamMembersTable = ({
                            team,
                            teamMembers,
                            onDelete,
                            onLeave
                          }) => {
  return (
    <TableContainer component={Paper} variant="outlined" square>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={30}/>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {teamMembers.map((teamMember) => (
            <TeamMembersTableRow
              team={team}
              teamMember={teamMember}
              onDelete={onDelete}
              onLeave={onLeave}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TeamMembersTable;
