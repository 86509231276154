/* eslint-disable */

import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import "./BasicLayout.scss";

import { Box } from "@mui/material";

import {
  CONFIRM_ROUTE,
  createTeamRoute,
  deactivateAccountRoute,
  paymentVerificationRoute,
} from "../../../constants/routes/routes";

import AccountInfo from "../../shared/AccountInfo/AccountInfo";

import SwitchTeam from "../DashboardLayout/components/SwitchTeam";

// helpers
import Null from "../../../helpers/fp/function/Null";
import BrandLogo from "../../shared/BrandLogo";
import useAuth from "../../../providers/AuthProvider/useAuth";

const LogoBlock = ({
  myAcc       = false,
  createTeam  = false,
  defaultPath = "",
}) => {
  return (
    <Box
      justifyContent="flex-start"
      textAlign="left"
      gap="var(--offset)"
      padding="calc(var(--offset) / 2)"
      component={Link}
      className={myAcc || createTeam ? "hide-name" : ""}
      to="#"
    >
      <BrandLogo />
      <span>
      {
        (myAcc      && "My Account")        ||
        (createTeam && "Create a new team") ||
        ""
      }
      </span>
    </Box>
  );
};

const BasicLayout = () => {
  const { authenticatedUser } = useAuth();

  const [leftSideComponents, setLiftSideComponents] = useState(Null);
  const [rightSideComponents, setRightSideComponents] = useState(Null);

  const [nextTeam, setNextTeam] = useState(null);
  const { pathname } = useLocation();

  const drawHeaderBlocks = useCallback((path) => {
    switch (path) {
      case deactivateAccountRoute: {
        setLiftSideComponents(<LogoBlock myAcc />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        break;
      }
      case paymentVerificationRoute: {
        setLiftSideComponents(<LogoBlock />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        break;
      }
      case createTeamRoute: {
        setLiftSideComponents(<LogoBlock createTeam />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        break;
      }
      // case NEEDS_ROUTE: {
      //   setLiftSideComponents(<LogoBlock myAcc />);
      //   setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
      //   setShowFooter(F);
      //   break;
      // }
      case CONFIRM_ROUTE: {
        setLiftSideComponents(<LogoBlock myAcc />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        break;
      }
      default: {
        setLiftSideComponents(<LogoBlock />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        break;
      }
    }
  }, []);

  useEffect(() => {
    drawHeaderBlocks(pathname);
  }, [drawHeaderBlocks, pathname, authenticatedUser]);

  return (
    <div className="basic-layout">
      <header>
        <div className="left-side-block d-flex align-center">
          {leftSideComponents}
        </div>
        <div className="right-side-block d-flex align-center">
          {rightSideComponents}
        </div>
      </header>
        <Box sx={{
          paddingTop: "4rem",
          height: "calc(100vh - 4rem)"
        }}>
          <Outlet />
        </Box>
      <SwitchTeam setNextTeam={setNextTeam} nextTeam={nextTeam} />
    </div>
  );
};

export default BasicLayout;
