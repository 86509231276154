import { Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";

const ExpandButton = ({ title, show, onClick }) => {
  return (
    <Button variant={show ? "contained" : "outlined"}
            endIcon={show ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            onClick={onClick}>
      {title}
    </Button>
  )
}

export default ExpandButton
