import axiosInstance from "../axiosInstance";

const deleteAccountCredential = (payload) => axiosInstance.post(`/users/account/credentials/delete`, payload);
const setDefaultAccountCredential = (payload) => axiosInstance.post(`/users/account/credentials/default`, payload);
const logoutAllAccountSessions = () => axiosInstance.post(`/users/account/sessions/logout`);
const updateAccount = (payload) => axiosInstance.put(`/users/account`, payload);
const getAccount = () => axiosInstance.get(`/users/account`);
const getAccountSessions = () => axiosInstance.get(`/users/account/sessions`);
const deactivateAccountCredential = (payload) => axiosInstance.post(`/users/account/deactivate`, payload);

const accountAPI = {
  deleteAccountCredential,
  setDefaultAccountCredential,
  logoutAllAccountSessions,
  updateAccount,
  getAccount,
  getAccountSessions,
  deactivateAccountCredential
}

export default accountAPI;
