/* eslint-disable */

import React from "react";
import { Avatar, TableCell, TableRow } from "@mui/material";
import TeamMemberContextMenu from "./TeamMemberContextMenu";
import useAuth from "../../../../../../../providers/AuthProvider/useAuth";
import { isTeamOwner } from "../../../../../../../modules/team/helpers";
import getFullName from "../../../../../../../helpers/string/getFullName";
import getNameAbbr from "../../../../../../../helpers/string/getNameAbbr";

const TeamMembersTableRow = ({
                               team,
                               teamMember,
                               onDelete,
                               onLeave
                             }) => {
  const { authenticatedUser } = useAuth();

  const isTeamMemberOwner = isTeamOwner(team, teamMember);
  const isAuthenticatedUserTeamMemberOwner = isTeamOwner(team, authenticatedUser);
  const isAuthenticatedUserTeamMember = authenticatedUser.id === teamMember.id;
  const showContextMenu = !isTeamMemberOwner && (isAuthenticatedUserTeamMemberOwner || isAuthenticatedUserTeamMember);

  return (
    <TableRow sx={{
      height: 48,
    }}>
      <TableCell>
        <Avatar>
        {getNameAbbr(
          teamMember.firstName,
          teamMember.lastName
        )}
        </Avatar>
      </TableCell>
      <TableCell>
        {getFullName(teamMember.firstName, teamMember.lastName)}
      </TableCell>
      <TableCell>
        {teamMember.email}
      </TableCell>
      <TableCell>
        { isTeamMemberOwner ? "Owner" : "Member" }
      </TableCell>
      <TableCell align="right">
        {showContextMenu && (
          <TeamMemberContextMenu
            teamMember={teamMember}
            isAuthenticatedUserTeamMember={isAuthenticatedUserTeamMember}
            isAuthenticatedUserTeamMemberOwner={isAuthenticatedUserTeamMemberOwner}
            onDelete={onDelete}
            onLeave={onLeave}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export default TeamMembersTableRow;
