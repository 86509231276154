import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { projectsRoute } from "../constants/routes/routes";
import useAuth from "../providers/AuthProvider/useAuth";
import useCurrentTeam from "../providers/MetadataProvider/hooks/useCurrentTeam";
import userTeamGuard from "../modules/user/userTeamGuard";

const OnlyActiveUserAndTeamRoute = ({ children, redirectPath = projectsRoute }) => {
  const { authenticatedUser } = useAuth();
  const { team } = useCurrentTeam();

  if (!userTeamGuard.canCreateResources(authenticatedUser, team)) {
    return <Navigate to={redirectPath} replace />;
  }

  /* eslint-disable-next-line */
  return children ? children : <Outlet />;
};

export default OnlyActiveUserAndTeamRoute;
