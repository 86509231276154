import { teamRoles } from "../../auth/contants";

const isTeamMember = (team, user) => {
  if (!team || !user) {
    return false;
  }

  return user.roles.some(role => role.name === `${team.id}:${teamRoles.member}` || role.name === `${team.id}:${teamRoles.owner}`)
}

export default isTeamMember
