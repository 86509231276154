import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";

const NotFound = ({ text, style }) => {
  return (
    <Box square sx={{
      height         : "100%",
      display        : "flex",
      alignItems     : "center",
      justifyContent : "center",
    }} style={style}>
      <Stack alignItems="center">
        <Typography variant="h1" fontWeight="bold" fontSize="12rem" color="primary"
                    sx={{ marginTop : "-1.5rem" }}>404</Typography>
        <Typography variant="h3">{text}</Typography>
        <Button variant="contained" sx={{ marginTop : "2rem" }} component={Link} to="/">Back to the project</Button>
      </Stack>
    </Box>
  );
};

export default NotFound;
