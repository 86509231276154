import { useContext } from "react";
import AuthContext from "./AuthContext";

const useAuth = () => {
  const {
    client,
    authenticatedUser
  } = useContext(AuthContext);

  return {
    authenticated: client.authenticated,
    configureSingInTOTPMethod: client.configureSingInTOTPMethod.bind(client),
    configureSingInPasskeysMethod: client.configureSingInPasskeysMethod.bind(client),
    updatePassword: client.updatePassword.bind(client),
    authenticatedUser,
    getAccessToken: client.getAccessToken.bind(client),
    getAccountLink: client.getAccountLink.bind(client),
    refreshToken: client.refreshToken.bind(client),
    logout: client.logout.bind(client),
  }
}

export default useAuth
