import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import React from "react";

const CloseIconButton = ({
                           tooltip = "Close",
                           onClick,
                           disabled,
                         }) => {
  return (
    <IconButton size="small"
                onClick={onClick}
                disabled={disabled}
                aria-label={tooltip}
    >
      <Close/>
    </IconButton>
  );
};

export default CloseIconButton;
