import axiosInstance from "../../../api/axiosInstance";

const getSecurityRules = (virtualMachineId) => axiosInstance.get(`/virtual-machines/${virtualMachineId}/security-rules`);
const createSecurityRule = (virtualMachineId, data) => axiosInstance.post(`/virtual-machines/${virtualMachineId}/security-rules`, data);
const deleteSecurityRule = (virtualMachineId, securityRuleId) => axiosInstance.delete(`/virtual-machines/${virtualMachineId}/security-rules/${securityRuleId}`);

const virtualMachineAPI = {
  getSecurityRules,
  createSecurityRule,
  deleteSecurityRule
}

export default virtualMachineAPI;
