/* eslint-disable */

// CORE
import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";

// MUI
import { ThemeProvider } from "@mui/material";

// STYLES
import "./styles/main.scss";
import { muiTheme } from "./assets/theme";
import "react-toastify/dist/ReactToastify.css";

// SLICK CAROUSEL STYLES`
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// COMPONENTS
import AppRouter from "./AppRouter";
import PageLoader from "./components/shared/PageLoader";
import ScrollToTop from "./components/common/ScrollToTop";

const App = () => {
  // const { 0: key, 1: setKey } = useState(zero);
  // window[Symbol.for("forceUpdate")] = () => setKey(inc);
  return (
    <ThemeProvider
      theme={muiTheme}
    >
      <ToastContainer
        autoClose={2000}
        closeOnClick
        draggable={false}
        hideProgressBar
        limit={3}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-center"
        rtl={false}
        theme="colored"
        style={{
          fontSize: "16px",
          maxWidth: "600px",
          width: "auto",
        }}
      />
      <Suspense fallback={<PageLoader/>}>
        <ScrollToTop/>
        <AppRouter/>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
