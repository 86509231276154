const isActive = (user) => {
  return user.emailVerified && !user.isPurged && !user.deactivatedAt;
}

const userGuard = {
  canCreateResources: (user) => {
    return isActive(user) && !user.suspendedAt;
  },
}

export default userGuard;
