import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import virtualMachineService from "../../../api/virtualMachine";

export const virtualMachinesQueryKey = "virtual-machines";

export const virtualMachinesQueryOptions = ({
                                             teamId,
                                             projectId
                                           }) => queryOptions({
  queryKey: [virtualMachinesQueryKey, {
    teamId,
    projectId
  }],
  queryFn: async () => {
    const { data } = await virtualMachineService.getVirtualMachines({
      teamId,
      projectId
    });

    return data;
  },
});

const useVirtualMachinesQuery = ({
                                  enabled = true,
                                  teamId,
                                  projectId
                                }) => {
  const { data, isFetching, isPending, error, isError, refetch } = useQuery({
    ...virtualMachinesQueryOptions({
      teamId,
      projectId
    }),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: [],
    placeholderData: keepPreviousData
  })

  return {
    items: data,
    isEmpty: data && !data?.length,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending
  }
}

export default useVirtualMachinesQuery;
