/**
  * @param {string} firstName
  * @param {string} lastName
  */
const getFullName = (firstName, lastName) => {
  const fullName = `${firstName ?? ""} ${lastName ?? ""}`;
  return fullName.trim() ? fullName : "—";
}

export default getFullName;
