import { queryOptions, useQuery } from "@tanstack/react-query";
import billingSettingService from "../services";
import getId from "../../../helpers/fp/models/getId";

export const BILLING_SETTING_BY_TEAM_QUERY_KEY = "team-billing-setting";

export const getByTeamBillingSettingOptions = (team) => queryOptions({
  queryKey: [BILLING_SETTING_BY_TEAM_QUERY_KEY, {
    teamId: getId(team)
  }],
  queryFn: () => billingSettingService.getByTeam(team),
});

const useTeamBillingSettingQuery = ({
  team,
  enabled = Boolean(getId(team)),
}) => {
  return useQuery({
    ...getByTeamBillingSettingOptions(team),
    enabled,
  })
};

export default useTeamBillingSettingQuery;
