import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack } from "@mui/material";
import FormikTextField from "../../../../components/shared/Formik/FormikTextField";
import useUpdateUserAccountMutation from "../../../../modules/account/queries/useUpdateUserAccountMutation";
import RowContainer from "../shared/RowContainer";
import StructuredRow from "../shared/StructuredRow";
import regex from "../../../../helpers/regex";
import getFullName from "../../../../helpers/string/getFullName";

const validationSchema = Yup.object({
  email: Yup.string(),
  emailVerified: Yup.bool(),
  firstName: Yup
    .string()
    .required("First name is required!")
    .max(32)
    .matches(regex.firstOrLastName, "Only letters, spaces and hyphens (-) are allowed!"),
  lastName: Yup
    .string()
    .required("Last name is required!")
    .max(32)
    .matches(regex.firstOrLastName, "Only letters, spaces and hyphens (-) are allowed!"),
});

const NameRow = ({ user }) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const updateUserAccountMutation = useUpdateUserAccountMutation()

  const formik = useFormik({
    initialValues: {
      firstName : user.firstName,
      lastName  : user.lastName,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await updateUserAccountMutation.mutateAsync({
          payload: {
            firstName : values.firstName,
            lastName  : values.lastName,
          }
        });

        setIsEditMode(false);

        toast.success("Profile was updated successfully!");
      } catch (e) {
        toast.error("Something went wrong, please try again later!");
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleToggleEdit = () => {
    setIsEditMode(value => !value);
    formik.resetForm();
  }

  return (
    <RowContainer>
      {isEditMode ? (
        <Box sx={{ padding: "0.5rem", width: "100%", }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2} maxWidth="sm">

              <FormikTextField formik={formik}
                               name="firstName"
                               label="First name"
                               disabled={formik.isSubmitting}
                               variant="outlined"
                               fullWidth
                               size="medium"
              />

              <FormikTextField formik={formik}
                               name="lastName"
                               label="Last name"
                               disabled={formik.isSubmitting}
                               variant="outlined"
                               fullWidth
                               size="medium"
              />

              <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                <Button variant="outlined"
                        disabled={formik.isSubmitting}
                        onClick={handleToggleEdit}>
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  disabled={!formik.dirty || !formik.isValid}
                  loading={formik.isSubmitting}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Box>
      ) : (

        <StructuredRow title="Name" content={getFullName(user.firstName, user.lastName)} actions={
          <Button variant="outlined" onClick={handleToggleEdit}>
            Edit
          </Button>
        }/>
      )}
    </RowContainer>
  )
}

export default NameRow;
