import React from "react";
import { Alert } from "@mui/material";
import { RefreshIconButton } from "../IconButtons";

const SomethingWentWrongAlert = ({ onRefresh }) => {
  return (
    <Alert
      severity="error"
      action={
        <RefreshIconButton onClick={onRefresh}/>
      }
    >
      Something went wrong! Please try again later.
    </Alert>
  )
}

export default SomethingWentWrongAlert;
