/* eslint-disable */

// CORE
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ENV from "../../constants/ENV";

// STORE
import virtualMachineStore from "../../store/virtualMachine";
import k8sStore from "../../store/k8s";
import databaseStore from "../../store/databases";
import appStore from "../../store/apps";
import serverModeStore from "../../store/serverMode";
import notificationStore from "../../store/notification";
import updateCachedApp from "../../modules/app/cache/updateCachedApp";
import deleteCachedApp from "../../modules/app/cache/deleteCachedApp";
import updateCachedDatabase from "../../modules/database/cache/updateCachedDatabase";
import updateCachedVirtualMachine from "../../modules/virtual-machine/cache/updateCachedVirtualMachine";
import deleteCachedVirtualMachine from "../../modules/virtual-machine/cache/deleteCachedVirtualMachine";
import updateCachedCluster from "../../modules/cluster/cache/cluster/updateCachedCluster";
import deleteCachedCluster from "../../modules/cluster/cache/cluster/deleteCachedCluster";
import updateCachedWorkerNode from "../../modules/cluster/cache/worker-node/updateCachedWorkerNode";
import updateCachedMasterNode from "../../modules/cluster/cache/master-node/updateCachedMasterNode";
import deleteCachedPool from "../../modules/cluster/cache/pool/deleteCachedPool";
import addPoolToCachedCluster from "../../modules/cluster/cache/pool/addPoolToCachedCluster";
import useAuth from "../../providers/AuthProvider/useAuth";
import deleteCachedVirtualMachineSecurityRule
  from "../../modules/virtual-machine/modules/security-rules/cache/deleteCachedVirtualMachineSecurityRule";
import updateCachedVirtualMachineSecurityRule
  from "../../modules/virtual-machine/modules/security-rules/cache/updateCachedVirtualMachineSecurityRule";

const SOCKET_URL = ENV.REACT_APP_SOCKET_URL;

const useSocket = ({ queryClient }) => {
  const { authenticatedUser, getAccessToken } = useAuth();
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();

  const handleUpdateBillingSettings = (billingSettings) => {

  };

  const handleRefreshToken = () => {
    // console.log('useSocket: handleRefreshToken');

    // refreshToken();
  }

  const handleUpdateCluster = (cluster) => {
    updateCachedCluster(queryClient, cluster);
    dispatch(k8sStore.actions.updateCluster(cluster));
  }

  const handleDeleteCluster = (cluster) => {
    deleteCachedCluster(queryClient, cluster);
    dispatch(k8sStore.actions.deleteCluster(cluster));
  }

  const handleDeletePool = (pool) => {
    deleteCachedPool(queryClient, pool);
    dispatch(k8sStore.actions.deletePool(pool));
  }

  const handleCreatePool = (pool) => {
    addPoolToCachedCluster(queryClient, pool);
    dispatch(k8sStore.actions.createdPool(pool));
  }

  const handleUpdateMasterNode = (masterNode) => {
    updateCachedMasterNode(queryClient, masterNode);
    dispatch(k8sStore.actions.updateMasterNodeNode(masterNode));
  }

  const handleUpdateWorkerNode = (workerNode) => {
    updateCachedWorkerNode(queryClient, workerNode);
    dispatch(k8sStore.actions.updateWorkerNodeNode(workerNode));
  }

  const handleUpdateVM = (vm) => {
    updateCachedVirtualMachine(queryClient, vm);
    dispatch(virtualMachineStore.actions.updateVirtualMachine(vm));
  }

  const handleDeleteVM = (vm) => {
    deleteCachedVirtualMachine(queryClient, vm);
    dispatch(virtualMachineStore.actions.deleteVirtualMachine(vm));
  }

  const handleFailVM = (vm) => {
    updateCachedVirtualMachine(queryClient, vm);
    dispatch(virtualMachineStore.actions.updateVirtualMachine(vm));
  }

  const handleUpdateDB = (db) => {
    updateCachedDatabase(queryClient, db);
    dispatch(databaseStore.actions.updateDatabase(db));
  }

  const handleDeleteDB = (db) => (
    dispatch(databaseStore.actions.deleteDatabase(db))
  );

  const handleFailDB = (db) => {
    updateCachedDatabase(queryClient, db);
    dispatch(databaseStore.actions.updateDatabase(db));
  }

  const handleUpdateApp = (app) => {
    updateCachedApp(queryClient, app);
    dispatch(appStore.actions.updateApp(app));
  }

  const handleDeleteApp = (app) => {
    deleteCachedApp(queryClient, app);
    dispatch(appStore.actions.deleteApp(app));
  }

  const handleFailApp = (app) => {
    updateCachedApp(queryClient, app);
    dispatch(appStore.actions.updateApp(app));
  }

  const handleUpdateSecurityRule = (rule) => {
    updateCachedVirtualMachineSecurityRule(queryClient, rule);
  }

  const handleDeleteSecurityRule = (rule) => {
    deleteCachedVirtualMachineSecurityRule(queryClient, rule);
  }

  const handleSuspendUser = (user) => {}

  const handleActivateUser = (user) => {}

  const handleDeactivateUser = (user) => {}

  const handleUpdateServerMode = (mode) =>
    dispatch(serverModeStore.actions.updateSeverMode(mode));

  const handleCreateNotification = () =>
    dispatch(
      notificationStore.asyncActions.getNotifications({
        page: 1,
        limit: 10,
      })
    );

  const token = getAccessToken();

  useEffect(() => {
    if (!authenticatedUser?.id || !token) return;

    const newSocket = io(SOCKET_URL, {
      autoConnect: false,
      reconnection: true,
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    setSocket(newSocket);
    newSocket.connect();

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, [authenticatedUser, token]);

  useEffect(() => {
    if (socket) {
      socket.on("updatedServerMode", handleUpdateServerMode);

      socket.on("updatedVirtualMachine", handleUpdateVM);
      socket.on("deletedVirtualMachine", handleDeleteVM);

      socket.on("updatedDatabase", handleUpdateDB);
      socket.on("deletedDatabase", handleDeleteDB);

      socket.on("updatedApp", handleUpdateApp);
      socket.on("deletedApp", handleDeleteApp);

      socket.on("updatedCluster", handleUpdateCluster);
      socket.on("deletedCluster", handleDeleteCluster);
      socket.on("updatedMasterNode", handleUpdateMasterNode);
      socket.on("deletedPool", handleDeletePool);
      socket.on("updatedWorkerNode", handleUpdateWorkerNode);

      // Not tested
      socket.on("updatedBillingSettings", handleUpdateBillingSettings);
      socket.on("failedVirtualMachine", handleFailVM);
      socket.on("failedDB", handleFailDB);
      socket.on("failedApp", handleFailApp);
      socket.on("createdPool", handleCreatePool);
      socket.on("suspendUser", handleSuspendUser);
      socket.on("activateUser", handleActivateUser);
      socket.on("deactivateUser", handleDeactivateUser);
      socket.on("createNotification", handleCreateNotification);
      socket.on("updateSecurityRule", handleUpdateSecurityRule);
      socket.on("deleteSecurityRule", handleDeleteSecurityRule);
      socket.on("refreshToken", handleRefreshToken);
    }

    return () => {
      if (socket) {
        socket.off("updatedBillingSettings", handleUpdateBillingSettings);
        socket.off("updatedVirtualMachine", handleUpdateVM);
        socket.off("deletedVirtualMachine", handleDeleteVM);
        socket.off("failedVirtualMachine", handleFailVM);
        socket.off("updatedDatabase", handleUpdateDB);
        socket.off("deletedDatabase", handleDeleteDB);
        socket.off("failedDB", handleFailDB);
        socket.off("updatedApp", handleUpdateApp);
        socket.off("deletedApp", handleDeleteApp);
        socket.off("failedApp", handleFailApp);
        socket.off("updatedCluster", handleUpdateCluster);
        socket.off("deletedCluster", handleDeleteCluster);
        socket.off("deletedPool", handleDeletePool);
        socket.off("updatedMasterNode", handleUpdateMasterNode);
        socket.off("updatedWorkerNode", handleUpdateWorkerNode);
        socket.off("createdPool", handleCreatePool);
        socket.off("suspendUser", handleSuspendUser);
        socket.off("activateUser", handleActivateUser);
        socket.off("deactivateUser", handleDeactivateUser);
        socket.off("updatedServerMode", handleUpdateServerMode);
        socket.off("createNotification", handleCreateNotification);
        socket.off("updateSecurityRule", handleUpdateSecurityRule);
        socket.off("deleteSecurityRule", handleDeleteSecurityRule);
        socket.off("refreshToken", handleRefreshToken);
      }
    };
  }, [socket]);
};

export default useSocket;
