import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import SessionsTableRow from "./SessionsTableRow/SessionsTableRow";

const SessionsTable = ({
                         sessions,
                       }) => {
  return (
    <TableContainer component={Paper} variant="outlined" square>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>IP address</TableCell>
            <TableCell>Remember me</TableCell>
            <TableCell>Started at</TableCell>
            <TableCell>Last access</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map((session, index) => (
            <SessionsTableRow
              number={index + 1}
              session={session}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SessionsTable;
