import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import SmallTooltip from "../../../../components/shared/SmallTooltip";
import { deactivateAccountRoute } from "../../../../constants/routes/routes";

const DeactivateRow = ({ style }) => {
  return (
    <Box style={style}>
      <Stack spacing={2}
             sx={{ alignItems: "center", justifyContent: "space-between" }}
             direction="row"
      >
        <SmallTooltip>
          Before you can deactivate your account, you will need to delete any teams you own or transfer ownership of them to another user.
        </SmallTooltip>
        <Button
          variant="outlined"
          component={Link}
          to={deactivateAccountRoute}
          color="error">
          Deactivate
        </Button>
      </Stack>
    </Box>
  )
}

export default DeactivateRow;
