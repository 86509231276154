/*eslint-disable*/

import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import teamService from "../../../api/team";

export const teamInvitesQueryOptions = (teamId) => queryOptions({
  queryKey: ["team-invites", {
    teamId
  }],
  queryFn: async () => {
    const { data } = await teamService.getInvites(teamId);

    return data;
  },
});

const useTeamInvitesQuery = ({
                        enabled = true,
                        teamId
                      }) => {
  const {
    data,
    isFetching,
    isPending,
    isSuccess,
    error,
    isError,
    refetch
  } = useQuery({
    ...teamInvitesQueryOptions(teamId),
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: null,
    placeholderData: keepPreviousData
  })

  return {
    team: data,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending,
    isSuccess
  }
}

export default useTeamInvitesQuery;
