import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SIGN_IN_METHODS } from "../../../../modules/account/constants";
import RowContainer from "../shared/RowContainer";
import StructuredRow from "../shared/StructuredRow";
import useAuth from "../../../../providers/AuthProvider/useAuth";
import ExpandButton from "../shared/ExpandButton";
import AuthDevicesTable from "../shared/AuthDevicesTable/AuthDevicesTable";
import { getUserCredential } from "./helpers";

const PasskeysRow = ({ user, isPending, onSetDefault, onDelete }) => {
  const { configureSingInPasskeysMethod } = useAuth();
  const [showDevices, setShowDevices] = useState(false);
  const handleToggleDevices = () => setShowDevices(value => !value);

  const userCredential = getUserCredential(user.credentials, SIGN_IN_METHODS.PASSKEYS);
  const hasDevices = !!userCredential?.devices.length;

  const handleSetDefault = () => {
    onSetDefault(userCredential);
  }

  useEffect(() => {
    if (!hasDevices) {
      setShowDevices(false)
    }
  }, [hasDevices]);

  return (
    <RowContainer>
      <Box sx={{ width: "100%" }}>
        <StructuredRow title="Passkeys"
                       isDefault={userCredential?.isDefault}
                       actions={
                         <>
                           {hasDevices && (
                             <>
                               {!userCredential?.isDefault && (
                                 <Button variant="outlined"
                                         disabled={isPending}
                                         onClick={handleSetDefault}>
                                   Set as default
                                 </Button>
                               )}

                               <ExpandButton title={`Devices (${userCredential.devices.length})`}
                                             onClick={handleToggleDevices}
                                             show={showDevices}/>
                             </>

                           )}
                           <Button variant="outlined"
                                   disabled={isPending}
                                   onClick={configureSingInPasskeysMethod}>
                             Set up
                           </Button>
                         </>
                       }/>
        {showDevices && hasDevices && (
          <>
            <Divider sx={{ marginTop: "1rem" }}/>

            <Box sx={{ padding: "1rem" }}>
              <AuthDevicesTable devices={userCredential.devices} onDelete={onDelete}/>
            </Box>
          </>
        )}
      </Box>
    </RowContainer>
  )
}

export default PasskeysRow
