import { useMutation, useQueryClient } from "@tanstack/react-query";
import teamService from "../../../api/team";
import { teamsQueryKey } from "./useTeamsQuery";
import { authCurrentUserQueryKey } from "../../auth/queries/useAuthCurrentUserQuery";

const useLeaveTeamMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutate, mutateAsync } = useMutation({
    mutationFn: ({ teamId }) => {
      return teamService.leaveTeam(teamId)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [authCurrentUserQueryKey]
      });
      await queryClient.invalidateQueries({
        queryKey: [teamsQueryKey]
      });
    }
  });

  return {
    isPending,
    mutate,
    mutateAsync,
  };
};

export default useLeaveTeamMutation;
