import prop from "lodash/fp/prop";

import billingSettingApi from "../api";
import billingSettingCustomerSchema from "../schemas/billingSettingCustomerSchema";

const getBillingSettingByTeam = (team) => billingSettingApi
  .getByTeam(team)
  .then(prop("data"))
;

const updateBillingSetting = (billingSetting) => billingSettingApi
  .update(billingSetting)
  .then(prop("data"))
;

const verifyBillingSetting = ({
                                billingSetting,
                                paymentIntent,
                                clientSecret
                              }) => billingSettingApi
  .verify({
    billingSetting,
    paymentIntent,
    clientSecret
  })
  .then(prop("data"))
;

/** @returns {Promise<ReturnType<typeof billingSettingCustomerSchema["parse"]>>} */
const getBillingSettingCustomer = (billingSetting) => billingSettingApi
  .getCustomer(billingSetting)
  .then(prop("data"))
  .then(billingSettingCustomerSchema.parse)
;

const setBillingSetting = ({ teamId, payload }) => billingSettingApi
  .set(teamId, payload)
  .then(prop("data"))
;

const billingSettingService = Object.freeze({
  getByTeam: getBillingSettingByTeam,
  update: updateBillingSetting,
  verify: verifyBillingSetting,
  getCustomer: getBillingSettingCustomer,
  set: setBillingSetting,
});

export default billingSettingService;
