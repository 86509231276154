import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";

const EditIconButton = ({
                         tooltip = "Edit",
                         onClick,
                         disabled,
                       }) => {
  return (
    <Tooltip title={tooltip} arrow>
      <span>
        <IconButton size="small" onClick={onClick} disabled={disabled}>
          <Edit/>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default EditIconButton;
