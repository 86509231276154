import getId from "../../../../helpers/fp/models/getId";
import { clustersQueryOptions } from "../../queries/useClustersQuery";

const getFieldsForMerge = (newCluster) => {
  const { project, ...others } = newCluster;
  return others
}

const updateCachedCluster = (queryClient, newCluster) => {
  const clustersQueryKey = clustersQueryOptions({
    teamId: getId(newCluster.team),
    projectId: getId(newCluster.project)
  }).queryKey;

  const cachedClusters = queryClient.getQueryData(clustersQueryKey);

  if (cachedClusters && !!cachedClusters.length) {
    const updatedClusters = cachedClusters.map(previousApp => getId(previousApp) === getId(newCluster)
      ? {
        ...previousApp,
        ...getFieldsForMerge(newCluster)
      } : previousApp);
    queryClient.setQueryData(clustersQueryKey, updatedClusters);
  }
}

export default updateCachedCluster;
