import axiosInstance, { getTrap } from "../../../api/axiosInstance";
import { TEAMS_PATH } from "../../../api/team";
import getId from "../../../helpers/fp/models/getId";
import concatPaths from "../../../helpers/fp/url/concatPaths";

export const BILLING_SETTINGS_PATH = "/billing-settings";
export const CUSTOMER_PATH         = "/retrieve";

const VERIFY_PATH                 = "/verify";

const getBillingSettingByTeam = (team) => getTrap(concatPaths([
  TEAMS_PATH,
  getId(team),
  BILLING_SETTINGS_PATH,
]));

const updateBillingSetting = (teamId, billingSettingId, payload) => axiosInstance.put(`/teams/${teamId}/billing-settings/${billingSettingId}`, payload);

const verifyBillingSetting = ({
  billingSetting,
  paymentIntent,
  clientSecret
}) => axiosInstance.post(concatPaths([
  BILLING_SETTINGS_PATH,
  getId(billingSetting),
  VERIFY_PATH,
]), { paymentIntent: getId(paymentIntent), clientSecret });

const getBillingSettingCustomer = (billingSettings) => getTrap(concatPaths([
  BILLING_SETTINGS_PATH,
  getId(billingSettings),
  CUSTOMER_PATH,
]));

const setBillingSetting = (teamId, payload) => axiosInstance.post(concatPaths([TEAMS_PATH, teamId, BILLING_SETTINGS_PATH]), payload);

const billingSettingApi = Object.freeze({
  getByTeam : getBillingSettingByTeam,
  verify    : verifyBillingSetting,
  update    : updateBillingSetting,
  getCustomer  : getBillingSettingCustomer,
  set       : setBillingSetting,
});

export default billingSettingApi;
