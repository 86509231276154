import axiosInstance, { deleteTrap, getTrap } from "../../../api/axiosInstance";
import getId from "../../../helpers/fp/models/getId";
import concatPaths from "../../../helpers/fp/url/concatPaths";
import { BILLING_SETTINGS_PATH } from "../../billingSettings/api";

export const PAYMENT_METHOD_PATH = "payment-methods";

const getPaymentMethodsByBillingSetting = (billingSetting) => getTrap(concatPaths([
  BILLING_SETTINGS_PATH,
  getId(billingSetting),
  PAYMENT_METHOD_PATH,
]));

const createPaymentMethod = ({ billingSetting, paymentMethod }) => axiosInstance.post(concatPaths([
  BILLING_SETTINGS_PATH,
  getId(billingSetting),
  PAYMENT_METHOD_PATH,
]), paymentMethod);

const deletePaymentMethod = ({ billingSetting, paymentMethod }) => deleteTrap(concatPaths([
  BILLING_SETTINGS_PATH,
  getId(billingSetting),
  PAYMENT_METHOD_PATH,
  getId(paymentMethod),
]));

const setDefaultPaymentMethod = ({ billingSetting, paymentMethod }) => axiosInstance.put(concatPaths([
  BILLING_SETTINGS_PATH,
  getId(billingSetting),
  PAYMENT_METHOD_PATH,
  getId(paymentMethod),
]));

const setPaymentMethod = ({
  billingSetting,
  paymentMethod,
  payload
}) => axiosInstance.post(concatPaths([
  BILLING_SETTINGS_PATH,
  getId(billingSetting),
  PAYMENT_METHOD_PATH,
  getId(paymentMethod),
]), payload);

const paymentMethodApi = Object.freeze({
  getByBillingSetting : getPaymentMethodsByBillingSetting,
  create              : createPaymentMethod,
  set                 : setPaymentMethod,
  delete              : deletePaymentMethod,
  setDefault          : setDefaultPaymentMethod
});

export default paymentMethodApi;
