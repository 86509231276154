/*eslint-disable*/

import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import DialogBase from "../../../../../components/shared/DialogBase/DialogBase";
import FormikTextField from "../../../../../components/shared/Formik/FormikTextField";
import useTeamQuery from "../../../../../modules/team/queries/useTeamQuery";
import useDeferredPromise from "../../../../../hooks/useDeferredPromise";
import { Alert, Button, Chip, Paper, Stack } from "@mui/material";
import useUpdateTeamMutation from "../../../../../modules/team/queries/useUpdateTeamMutation";
import Typography from "@mui/material/Typography";
import { Add } from "@mui/icons-material";
import Box from "@mui/material/Box";
import EmailValidator from "email-validator";
import useTeamInviteMembersMutation from "../../../../../modules/teamInvites/queries/useTeamInviteMembersMutation";
import TagsArea from "../../../../../components/shared/TagsArea";

const validationSchema = Yup.object({
  name: Yup
    .string()
    .required("Name is required"),
  email: Yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

const InviteTeamMembersModalDialog = forwardRef((props, ref) => {
  const { defer, deferRef } = useDeferredPromise();

  const [initialization, setInitialization] = useState(false);
  const [open, setOpen] = useState(false);
  const [openProps, setOpenProps] = useState(false);

  const [emails, setEmails] = useState([]);

  const allEmails = [...emails];

  useImperativeHandle(
    ref,
    () => ({
      open: (openProps) => {

        if (!openProps.teamId) {
          console.error("EditTeamModalDialog: teamId is required!")
          return;
        }

        setOpenProps(openProps);
        setInitialization(true);
        return defer().promise;
      },
    }),
    [],
  );

  const teamInviteMembersMutation = useTeamInviteMembersMutation()

  const validationSchema = Yup.object({
    email: Yup
      .string()
      .trim()
      .required("Email is required")
      .test("not-valid-email",
        "This email is not valid!",
        email => EmailValidator.validate(email),
      )
      .test("already-exists",
        "This email is already used for the invite!",
        email => !allEmails.includes(email),
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async ({ email }, { resetForm }) => {
      setEmails(prev => [...prev, email.toLowerCase()]);
      resetForm();
    },
  });

  useEffect(() => {
    if (initialization) {
      if (true) {


        setOpen(true);
      } else {
        toast.error("Something went wrong, please try again later!");

        // eslint-disable-next-line no-use-before-define
        handleClose(false);
      }
    }
  }, [initialization])

  const handleClose = (success = false) => {
    setOpen(false);
    setInitialization(false);
    formik.resetForm();
    setEmails([]);
    deferRef.resolve(success);
  };


  const handleAddEmail = () => {
    formik.submitForm();
  };

  const handleDeleteEmail = (email) => {
    setEmails(prevEmails => prevEmails.filter(prevEmail => prevEmail !== email));
  };

  const handleKeyDownEmail = (event) => {
    if (event.code === "Enter") {

      if (formik.isValid) {
        formik.submitForm();
      }

      event.preventDefault();
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    await teamInviteMembersMutation.mutateAsync({
      teamId: openProps.teamId,
      payload: {
        emails
      }
    });

    handleClose(true);
  }

  const isPending = teamInviteMembersMutation.isPending;

  return (
    <DialogBase.Root onClose={handleClose} open={open} title="Edit team info" maxWidth="xs">
      <form onSubmit={onSubmit}>
        <DialogBase.Content>

          <Typography variant="body2" gutterBottom>
            Enter email address of members to be invited to the team
          </Typography>

          <Stack direction="row" alignItems="start" spacing={1}>
            <FormikTextField formik={formik}
                             name="email"
                             variant="outlined"
                             placeholder="Email"
                             fullWidth
                             disabled={isPending}
                             onKeyDown={handleKeyDownEmail}

            />

            <Button
              variant="contained"
              size="small"
              disabled={!formik.dirty || !formik.isValid || isPending}
              onClick={handleAddEmail}
              sx={{ minWidth: 0, p: 1 }}
            >
              <Add/>
            </Button>
          </Stack>

          <Typography variant="body2" style={{ marginTop: "1rem" }} gutterBottom>
            Invites
          </Typography>

          <TagsArea
            tags={emails}
            onDelete={handleDeleteEmail}
            disabled={isPending}
            placeholder="Your invites are empty..."
          />
        </DialogBase.Content>
        <DialogBase.Actions>
          <LoadingButton type="submit"
                         disabled={!emails.length}
                         autoFocus
                         fullWidth
                         loading={isPending}
                         variant="contained">
            Invite Team Members
          </LoadingButton>
        </DialogBase.Actions>
      </form>
    </DialogBase.Root>
  );
});

export default InviteTeamMembersModalDialog;
