import { z } from "zod";

const billingSettingCustomerSchema = z.object({
  id               : z.string(),
  invoice_settings : z.object({
    default_payment_method : z.string().nullable(),
  }).nullable(),
});

export default billingSettingCustomerSchema;
