import { createAsyncThunk } from '@reduxjs/toolkit';
import databaseApi from "../../api/database";

const databasesAsyncActions = Object.freeze({
  createDatabase : createAsyncThunk('post/databases', databaseApi.createDatabase),
  /** @depricated */
  getDatabases   : createAsyncThunk('get/databases', async (params) => databaseApi.getDatabases(params)),
  deleteDatabase : createAsyncThunk('delete/databases', async (params) => databaseApi.deleteDatabase(params)),

  getByTeam      : createAsyncThunk('get/databases/team',    databaseApi.getByTeam),
  getByProject   : createAsyncThunk('get/databases/project', databaseApi.getByProject),
});

export default databasesAsyncActions;
