import { getTrap } from "../axiosInstance";

export const PROVIDER_PATH = "providers";

const getProviders = ((params) => getTrap(`/${PROVIDER_PATH}?type=${params.type}`));
const getProvidersByType = (type) => getTrap(`/${PROVIDER_PATH}?type=${type}`);

const providerApi = Object.freeze({
  getProviders,
  getProvidersByType,
});

export default providerApi;
