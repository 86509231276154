import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import prop from "lodash/fp/prop";
import projectAPI from "../api";

export const projectsQueryKey = "projects";

export const projectsQueryOptions = (teamId) => queryOptions({
  queryKey: [projectsQueryKey, {
    teamId
  }],
  queryFn: async () => {
    return projectAPI.getProjects({ teamId }).then(prop("data"))
  },
});

const useProjectsQuery = ({
                         enabled = true,
                         teamId
                       }) => {
  const {
    data,
    isFetching,
    isPending,
    isFetched,
    error,
    isError,
    refetch
  } = useQuery({
    ...projectsQueryOptions(teamId),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: [],
    placeholderData: keepPreviousData
  })

  return {
    projects: data ?? [],
    isEmpty: data && !data?.length,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending,
    isFetched
  }
}

export default useProjectsQuery;
