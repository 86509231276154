import { complement } from "lodash/fp";
import concatPaths from "../../helpers/fp/url/concatPaths";
import getPathName from "../../helpers/fp/window/getPathName";
import { RESOURCE_TYPES } from "../common";

const CREATE_PATH         = "create";
const NEW_PATH            = "new";
const PAYMENT_METHOD_PATH = "payment-method";

const loginRoute = "/login";
const registrationsRoute = "/registrations";
const INVITE_ROUTE = "/invite";
const CONFIRM_ROUTE = "/confirm";
const REACTIVATE_ROUTE = "/reactivate";
const REFERRAL_ROUTE = "/referral";
const waitingList = "/waitinglist";
const maintenanceRoute = "/maintenance";

const AUTH_ROUTE = "/auth";

const ACCESSIBLE_UN_AUTH_ROUTES = Object.freeze([
  loginRoute,
  AUTH_ROUTE,
  registrationsRoute,
  INVITE_ROUTE,
  waitingList,
  maintenanceRoute,
]);

const accountRoute = "/account";
const deactivateAccountRoute = "/account/deactivate";
const teamRoute = "/teams/:teamId"
const teamRouteBuilder = (teamId) => `/teams/${teamId}`;
const selectTeamRoute = "/select-team";
const createTeamRoute = "/create-team";
const createTeamRouteBuilder = (teamId) => `${createTeamRoute}/${teamId}`;
const createTeamInviteStepRouteBuilder = (teamId) => `${createTeamRoute}/${teamId}/invite`;
const createTeamPaymentMethodStepRouteBuilder = (teamId) => `${createTeamRoute}/${teamId}/payment-method`;

const settingsRoute = "/settings";
const settingsRouteBuilder = (tab = null) => settingsRoute + (tab ? `?tab=${tab}` : "");

const profileRoute = "/profile";
const virtualMachineRoute = "/virtual-machines";
const virtualMachineRouteBuilder = (virtualMachineId) => `/virtual-machines/${virtualMachineId}`;
const databaseRoute = "/databases";
const databaseRouteBuilder = (databaseId) => `/databases/${databaseId}`;
const appRoute = "/apps";
const appRouteBuilder = (appId) => `/apps/${appId}`;
const virtualMachineNewRoute = "/virtual-machines/new";
const databaseNewRoute = "/databases/new";
const appNewRoute = concatPaths([appRoute, NEW_PATH]);
const projectItemRoute = "/projects/:projectId";
const projectItemRouteBuilder = (projectId) => `/projects/${projectId}`;
const projectsRoute = "/projects";
const projectsNewRoute = "/projects/new";
const paymentVerificationRoute = "/payment-verification";
const billingRoute = "/billing";
const NEEDS_ROUTE = "/needs";
const TICKETS_ROUTE = "/tickets";
const ticketRouteBuilder = (ticketId) => `/tickets/${ticketId}`;
const SUPPORT_ROUTE = TICKETS_ROUTE;
const DOCS_ROUTE = "/docs";
const SECRETS_ROUTE = "/secrets";
const KUBERNETES_ROUTE = "/clusters";
const INVITE_PATH = "invite";
const KUBERNETES_NEW_ROUTE = concatPaths([KUBERNETES_ROUTE, NEW_PATH]);
const TICKET_CREATE_ROUTE = concatPaths([TICKETS_ROUTE, CREATE_PATH]);

// helpers
const isAccessibleUnAuth = () =>
  ACCESSIBLE_UN_AUTH_ROUTES.some((pathName) =>
    getPathName().startsWith(pathName)
  );
const isNeedAuth = complement(isAccessibleUnAuth);

const resourceByTypeToLink = (type, id) => {
  return ({
    [RESOURCE_TYPES.APP]: `/apps/${id}`,
    [RESOURCE_TYPES.DATABASE]: `/databases/${id}`,
    [RESOURCE_TYPES.VM]: `/virtual-machines/${id}`,
  })[type] ?? "#"
}

export {
  accountRoute,
  maintenanceRoute,


  ACCESSIBLE_UN_AUTH_ROUTES,
  waitingList,
  REACTIVATE_ROUTE,
  loginRoute,
  settingsRoute,
  settingsRouteBuilder,
  profileRoute,
  projectItemRoute,
  projectsRoute,
  projectItemRouteBuilder,
  virtualMachineRoute,
  virtualMachineRouteBuilder,
  databaseRoute,
  databaseRouteBuilder,
  appRoute,
  appRouteBuilder,
  virtualMachineNewRoute,
  databaseNewRoute,
  appNewRoute,
  registrationsRoute,
  CONFIRM_ROUTE,
  paymentVerificationRoute,
  NEEDS_ROUTE,
  selectTeamRoute,
  createTeamRoute,
  createTeamRouteBuilder,
  createTeamInviteStepRouteBuilder,
  createTeamPaymentMethodStepRouteBuilder,
  billingRoute,
  deactivateAccountRoute,
  SUPPORT_ROUTE,
  DOCS_ROUTE,
  TICKETS_ROUTE,
  TICKET_CREATE_ROUTE,
  ticketRouteBuilder,
  SECRETS_ROUTE,
  INVITE_ROUTE,
  projectsNewRoute,
  KUBERNETES_ROUTE,
  KUBERNETES_NEW_ROUTE,
  AUTH_ROUTE,
  REFERRAL_ROUTE,
  CREATE_PATH,
  NEW_PATH,
  PAYMENT_METHOD_PATH,
  INVITE_PATH,
  isAccessibleUnAuth,
  isNeedAuth,
  resourceByTypeToLink,
  teamRoute,
  teamRouteBuilder
};
