import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Button, CircularProgress, Skeleton } from "@mui/material";
import extractResponseError from "../../helpers/request/extractResponseError";

const ContainerWithLoader = ({
                               isLoading,
                               children,
                               height = 200,
                               isError = false,
                               error,
                               onRetry,
                               loaderDelay = 1500
                             }) => {

  const [showLoader, setShowLoader] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    if (isLoading) {
      timer.current = setTimeout(() => {
        setShowLoader(true);
      }, loaderDelay);
    } else {
      clearTimeout(timer.current);
      setShowLoader(false);
    }

    return () => {
      clearTimeout(timer.current);
    }
  }, [isLoading]);


  if (showLoader) {
    return (
      <Box sx={{
        height,
        position: "relative",
      }}>
        <Skeleton
          width="100%"
          variant="rounded"
          height="100%"
          animation="wave"
        />
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <CircularProgress/>
        </Box>
      </Box>
    );
  }

  if (isError) {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={onRetry}>
            Retry
          </Button>
        }
      >
        {extractResponseError(error) ?? "Ooops... Something went wrong, please try again later."}
      </Alert>
    )
  }

  return children;
};

export default ContainerWithLoader;
