import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import useDeferredPromise from "../../../hooks/useDeferredPromise";
import DialogBase from "../DialogBase/DialogBase";

const AlertDialog = forwardRef(({
                                  confirmButtonText = "OK",
                                  cancelButtonText = "Cancel",
                                  title = "Title",
                                  description = null,
                                  showCloseButton = true
                                }, ref) => {
  const { defer, deferRef } = useDeferredPromise();

  const [open, setOpen] = useState(false);
  const [pending, setPending] = useState(false);
  // {confirmButtonText, cancelButtonText, title, description, confirmCallback}
  const [openProps, setOpenProps] = useState(null);
  const _description = openProps?.description ?? description;

  useImperativeHandle(
    ref,
    () => ({
      open: (props) => {
        setOpenProps(props)
        setOpen(true)

        return defer().promise
      },
    }),
    []
  );

  const handleConfirm = async () => {
    if (openProps.confirmCallback) {
      let succeeded = false;

      setPending(true);

      try {
        await openProps.confirmCallback();

        succeeded = true;
      } catch (e) {
        console.log(e)
      }

      setPending(false);

      if (!succeeded) return;
    }

    setOpen(false);

    setTimeout(() => {
      setOpenProps(null)
    }, 500)

    deferRef.resolve(true);
  }

  const handleClose = () => {
    setOpen(false);
    setOpenProps(null)
    deferRef.resolve(false)
  };

  return (
    <DialogBase.Root onClose={handleClose}
                     disabled={pending}
                     open={open}
                     title={openProps?.title ?? title}
                     showCloseButton={showCloseButton}
    >
      {_description && (
        <DialogBase.Content>
          {_description}
        </DialogBase.Content>
      )}
      <DialogBase.Actions>
        <Button onClick={handleClose} variant="outlined" disabled={pending}>
          {openProps?.cancelButtonText ?? cancelButtonText}
        </Button>
        <LoadingButton onClick={handleConfirm} autoFocus variant="contained" loading={pending}>
          {openProps?.confirmButtonText ?? confirmButtonText}
        </LoadingButton>
      </DialogBase.Actions>
    </DialogBase.Root>
  );
})

export default AlertDialog;
