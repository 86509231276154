import { InputAdornment, Stack, TextField } from "@mui/material";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import ErrorIcon from "../Icons/ErrorIcon";

const ERROR_PLACEMENTS = {
  HELPER_TEXT: "helperText",
  END_ADORNMENT: "endAdornment",
};

const FormikTextField = ({
                           formik,
                           name,
                           placeholder,
                           label,
                           variant = "outlined",
                           type = "text",
                           size = "small",
                           disabled,
                           info,
                           transformations,
                           errorPlacement = ERROR_PLACEMENTS.HELPER_TEXT, // "helperText" | "endAdornment"
                           ...props
                         }) => {

  const hasError = Boolean(formik.touched[name]) && Boolean(formik.errors[name]);

  const errorPlacementIsHelperText = errorPlacement === ERROR_PLACEMENTS.HELPER_TEXT;

  return (
    <TextField variant={variant}
               size={size}
               type={type}
               placeholder={placeholder}
               name={name}
               label={label}
               value={formik.values[name]}
               onChange={(event) => {
                 const inputValue = event.target.value;

                 const transformedValue = transformations
                   ? transformations.reduce((result, transform) => transform(result), inputValue)
                   : inputValue;

                 formik.handleChange({
                   target: {
                     name: event.target.name,
                     value: transformedValue,
                   },
                 });
               }}
               onBlur={formik.handleBlur}
               error={hasError}
               disabled={formik.isSubmitting || disabled}
               InputProps={{
                 endAdornment: (hasError && !errorPlacementIsHelperText) || info ? (
                   <InputAdornment position="end">
                     <Stack direction="row" alignItems="center">
                       {
                         hasError && !errorPlacementIsHelperText && (
                           <ErrorIcon tooltip={formik.errors[name]}/>
                         )
                       }
                       {info && (
                         <Tooltip title={info} arrow placement="top">
                           <InfoOutlined fontSize="small"/>
                         </Tooltip>
                       )}

                     </Stack>


                   </InputAdornment>
                 ) : undefined,
               }}
               helperText={hasError && errorPlacementIsHelperText ? formik.errors[name] : undefined}
               {...props}
    />
  );
};

export default FormikTextField;
