import React from "react";
import { Link } from "react-router-dom";
import { Alert, Container } from "@mui/material";

const SuspendTip = ({
                      isTeamOwner
                    }) => {
  const message = isTeamOwner ? "Your account is suspended" : "This team is suspended";

  return (
    <Container maxWidth="lg" style={{ marginBottom: "1rem" }}>
      <Alert severity="warning">
        {message}, check the <Link className="inherit" style={{ fontWeight: "bold", textDecoration: "underline" }}
                                   to="/billing">payment</Link> of your subscription.
      </Alert>
    </Container>
  );
};

export default SuspendTip;
