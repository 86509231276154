import { virtualMachinesSecurityRulesQueryOptions } from "../queries/useVirtualMachineSecurityRules";
import getId from "../../../../../helpers/fp/models/getId";

const deleteCachedVirtualMachineSecurityRule = (queryClient, securityRule) => {
  queryClient.setQueryData(
    virtualMachinesSecurityRulesQueryOptions({ virtualMachineId: securityRule.virtualMachine }).queryKey,
    cachedVirtualMachineSecurityRules => cachedVirtualMachineSecurityRules?.filter(_securityRule => getId(_securityRule) !== getId(securityRule) ?? [])
  )
}

export default deleteCachedVirtualMachineSecurityRule;
