import { useContext } from "react";
import MetadataContext from "../MetadataContext";

const useCurrentTeam = () => {
  const { team } = useContext(MetadataContext);

  return {
    team,
  }
};

export default useCurrentTeam;
