import { queryOptions, useQuery } from "@tanstack/react-query";
import vaultTokenJWT from "../../../helpers/vaultTokenJWT";
import teamService from "../../../api/team";
import vaultToken from "../../../helpers/vaultToken";

export const vaultTokenOptions = ({ userId, teamId }) => queryOptions({
  queryKey: ["vault-token", {
    userId,
    teamId
  }],
  queryFn: async () => {
    const { data } = await teamService.getVaultToken(teamId);

    if (data.token) {
      vaultTokenJWT.value = data.token;
      vaultToken.pop();
    }

    return data;
  },
});

const useVaultTokenQuery = ({
                              enabled,
                              userId,
                              teamId
                            }) => {
  const {
    data,
    isFetching,
    isPending,
    isSuccess,
    isError,
    error,
    failureCount,
    refetch
  } = useQuery({
    ...vaultTokenOptions({
      userId,
      teamId
    }),
    enabled,
    placeholderData: null,
    refetchOnWindowFocus: false,
  })

  return {
    data,
    isFetching,
    isPending,
    isSuccess,
    isError,
    error,
    failureCount,
    refetch
  }
}

export default useVaultTokenQuery;
