import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CloseIconButton from "../IconButtons/CloseIconButton";

const DialogBaseRoot = ({
                          open,
                          title,
                          onClose,
                          children,
                          disabled,
                          maxHeight,
                          showCloseButton = true,
                          maxWidth = "sm"
                        }) => {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#fff",
          color: "#000000",
          borderRadius: 0,
          overflow: "hidden",
          maxHeight,
        },
      }}
    >

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle variant="h3" sx={{ flexShrink: 1, paddingInline: "1rem" }}>
          {title}
        </DialogTitle>
        {
          showCloseButton && (
            <div style={{ paddingTop: "0.5rem", paddingRight: "0.5rem" }}>
              <CloseIconButton disabled={disabled}
                               onClick={onClose}
              />
            </div>
          )
        }
      </div>

      {children}
    </Dialog>
  );
};

const DialogBaseContent = ({
                             children,
                           }) => {
  return (
    <DialogContent sx={{
      paddingBlock: 0,
      paddingInline: "1rem"
    }}>
      {children}
    </DialogContent>
  );
};

const DialogBaseActions = ({
                             children,
                           }) => {
  return (
    <DialogActions sx={{ padding: "1rem" }}>
      {children}
    </DialogActions>
  );
};


const DialogBase = {
  Root: DialogBaseRoot,
  Content: DialogBaseContent,
  Actions: DialogBaseActions,
};

export default DialogBase;
