/*eslint-disable*/

import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import teamService from "../../../api/team";

export const teamQueryOptions = (teamId) => queryOptions({
  queryKey: ["team", {
    teamId
  }],
  queryFn: async () => {
    const { data } = await teamService.getTeam({ teamId });

    return data;
  },
});

const useTeamQuery = ({
                        enabled = true,
                        teamId
                      }) => {
  const {
    data,
    isFetching,
    isFetched,
    isPending,
    isSuccess,
    error,
    isError,
    refetch
  } = useQuery({
    ...teamQueryOptions(teamId),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    initialData: null,
    placeholderData: keepPreviousData
  })

  return {
    team: data,
    isNotFound: error?.response?.status === 404,
    error,
    isError,
    refetch,
    isFetching,
    isPending: !isFetched || isPending,
    isSuccess
  }
}

export default useTeamQuery;
