import React from "react"
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useAuthenticatedUserTeams from "../../modules/team/hooks/useAuthenticatedUserTeams";
import { createTeamRoute, projectsRoute } from "../../constants/routes/routes";
import TeamItem from "./TeamItem";
import getId from "../../helpers/fp/models/getId";

const SelectTeamPage = () => {
  const navigate = useNavigate();

  const {
    currentTeam,
    teams,
    selectTeam,
    selectTeamIsPending
  } = useAuthenticatedUserTeams();

  const handleSelectTeam = async (team) => {
    try {
      await selectTeam(getId(team));
      await navigate(projectsRoute);
    } catch (e) {
      toast.error("Unable to select the team!")
      console.log(e)
    }
  }

  return (
    <Container maxWidth="md" sx={{ padding: "8rem" }}>
      {
        !!currentTeam && (
          <Button variant="outlined" component={Link} to={projectsRoute} size="small">
            Back to projects
          </Button>
        )
      }

      <Typography style={{ marginTop: "1rem" }} variant="h1" gutterBottom>Select or create team</Typography>

      <Button
        variant="contained"
        component={Link}
        disabled={selectTeamIsPending}
        to={createTeamRoute}>
        Create a new team
      </Button>

      <Stack style={{ marginTop: "1rem" }} spacing={1}>
        {teams.map(team => (
          <TeamItem team={team}
                    disabled={selectTeamIsPending}
                    onSelect={handleSelectTeam}
                    isCurrent={team.id === currentTeam?.id}
          />
        ))}
      </Stack>
    </Container>
  )
}

export default SelectTeamPage
