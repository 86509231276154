import getId from "../../../helpers/fp/models/getId";
import { virtualMachineQueryOptions } from "../queries/useVirtualMachineQuery";
import { virtualMachinesQueryOptions } from "../queries/useVirtualMachinesQuery";
import { isVirtualMachineDeleted } from "../../../constants/resources/virtualMachine";

const getFieldsForMerge = (newVirtualMachine) => {
  const { project, ...others } = newVirtualMachine;
  return others
}

const updateCachedVirtualMachine = (queryClient, newVirtualMachine) => {
  const virtualMachineId = getId(newVirtualMachine);

  const virtualMachineQueryKey = virtualMachineQueryOptions({
    id: virtualMachineId
  }).queryKey;

  const virtualMachinesQueryKey = virtualMachinesQueryOptions({
    teamId: getId(newVirtualMachine.team),
    projectId: getId(newVirtualMachine.project),
  }).queryKey;

  const cachedVirtualMachine = queryClient.getQueryData(virtualMachineQueryKey);
  const cachedVirtualMachines = queryClient.getQueryData(virtualMachinesQueryKey);

  const isDeleted = isVirtualMachineDeleted(newVirtualMachine);

  if (cachedVirtualMachine) {
    queryClient.setQueryData(virtualMachineQueryKey, isDeleted ? null :
      {
        ...cachedVirtualMachine,
        ...getFieldsForMerge(newVirtualMachine)
      }
    );
  }

  if (cachedVirtualMachines && !!cachedVirtualMachines.length) {
    if (isDeleted) {
      queryClient.setQueryData(virtualMachinesQueryKey, cachedVirtualMachines.filter(previousApp => getId(previousApp) !== getId(newVirtualMachine)));
    } else {
      queryClient.setQueryData(virtualMachinesQueryKey, cachedVirtualMachines.map(previousVirtualMachine => getId(previousVirtualMachine) === getId(newVirtualMachine)
        ? {
          ...previousVirtualMachine,
          ...getFieldsForMerge(newVirtualMachine)
        } : previousVirtualMachine));
    }
  }
}

export default updateCachedVirtualMachine;
