import prop from "lodash/fp/prop";

import paymentMethodApi from "../api";

const getPaymentMethodsByBillingSetting = (billingSetting) => paymentMethodApi
  .getByBillingSetting(billingSetting)
  .then(prop("data"))
;

const createPaymentMethod = ({ billingSetting, paymentMethod }) => paymentMethodApi
  .create({ billingSetting, paymentMethod })
  .then(prop("data"))
;

const deletePaymentMethod = ({ billingSetting, paymentMethod }) => paymentMethodApi
  .delete({ billingSetting, paymentMethod })
  .then(prop("data"))
;

const setDefaultPaymentMethod = ({ billingSetting, paymentMethod }) => paymentMethodApi
  .setDefault({ billingSetting, paymentMethod })
  .then(prop("data"))
;

/**
 * Sets the payment method.
 *
 * @param {Object} params
 * @param {string} params.billingSetting - The billing setting ID.
 * @param {string} params.paymentMethod - The payment method ID.
 * @param {Object} params.payload - The payload object.
 * @param {string} params.payload.returnURL - The return URL after setting the payment method.
 * @returns {Promise<any>} A promise resolving to the API response data.
 */
const setPaymentMethod = ({
                            billingSetting,
                            paymentMethod,
                            payload,
                          }) => paymentMethodApi
  .set({
    billingSetting,
    paymentMethod,
    payload
  })
  .then(prop("data"))
;

const paymentMethodService = Object.freeze({
  getByBillingSetting: getPaymentMethodsByBillingSetting,
  create: createPaymentMethod,
  delete: deletePaymentMethod,
  setDefault: setDefaultPaymentMethod,
  set: setPaymentMethod,
});

export default paymentMethodService;
